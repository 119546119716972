/* eslint-disable @typescript-eslint/no-explicit-any, no-underscore-dangle */
import { isTestMode } from "@feniks-pwa/web-app/config/env";
import storageNames from "web/constants/storageNames";
import { getToken } from "web/constants/urls";
import type { ILSToken } from "web/types/Token";
import jsonParse from "web/utils/data/parser/string/jsonParse";
import isValidToken from "web/utils/system/essentials/isValidToken";
import isValidTokenAccess from "web/utils/system/essentials/isValidTokenAccess";
import BrowserPersistence from "web/utils/system/storage/storage/browserPersistence";
import isMSIQuery from "web/features/api/utils/isMSIQuery";
import {
  availableLanguages,
  defaultLanguage,
} from "../Layout/Translations/LanguageWrapper";
import {
  IApiRequest,
  IApiRequestMethods,
  IApiRequestOpts,
  IRequestOpts,
} from "./apiRequestTypes";
import * as MulticastCache from "./multicastCache";
import renewAfterGetToken from "./renewAfterGetToken";
import ResponseError from "./responseError";

const storage = new BrowserPersistence();
const notAuthorizedUrlsSet = new Set();

class ApiRequest implements IApiRequest {
  controller = new AbortController();

  private _promise?: Promise<unknown>;

  opts: IApiRequestOpts;

  constructor(public resourceUrl: string, opts: Partial<IApiRequestOpts> = {}, tokenFromRenewal?: string) {
    const token = tokenFromRenewal || storage.getItem(isMSIQuery(resourceUrl) ? storageNames.tokenAccess : storageNames.token)
    const languageKey =
      window.localStorage.getItem(storageNames.language) ||
      storage.getItem(storageNames.language) ||
      defaultLanguage;

    const language = availableLanguages.find((availableLanguage) => {
      return (
        availableLanguage.key === languageKey ||
        availableLanguage.code === languageKey
      );
    });

    this.opts = {
      method: IApiRequestMethods.GET,
      signal: this.controller.signal,
      credentials: "include",
      ...opts,
      headers: new Headers({
        authorization: token ? `Bearer ${token}` : "",
        "Content-language": language?.code || "",
        "Content-type": "application/json",
        Accept: "application/json",
        ...(opts?.headers || ({} as Headers)),
      }),
    };
  }

  run(): void {
    if (this._isMulticastable) {
      this._promise = this._fetchMulticast();
    } else {
      this._promise = this._fetch();
    }
  }

  async getResponse(): Promise<unknown> {
    if (!this._promise) {
      throw new Error(
        "ApiRequest#getResponse() called before ApiRequest#run(), so no promise exists yet"
      );
    }
    if (this._isMulticastable) {
      const response = await this._promise;
      return (<Response>response).clone();
    }
    return this._promise;
  }

  abortRequest(): void {
    if (this.controller && typeof this.controller.abort === "function") {
      this.controller.abort();
    }
  }

  private _fetch(): Promise<Response> {
    if (typeof this.resourceUrl !== "string") {
      throw new Error("Provided url is not a string");
    }
    return fetch(this.resourceUrl, this.opts)
      .then(
        (res) => {
          MulticastCache.remove(this);
          return res;
        },
        (e) => {
          MulticastCache.remove(this);
          throw e;
        }
      )
      .then((response) => {
        if (!response.ok) {
          return response.text().then((bodyText) => {
            throw new ResponseError(
              {
                method: this.opts.method as string,
                resourceUrl: this.resourceUrl,
                response,
                bodyText,
              },
              // added for 401 error logging
              response.status === 401 && this.resourceUrl !== getToken
                ? { tokenInfo: getTokenInfo() }
                : null
            );
          });
        }
        return response;
      });
  }

  private _fetchMulticast(): Promise<unknown> {
    const inflightMatch = MulticastCache.match(this);
    const rolling = this._isRolling;

    if (inflightMatch && !rolling) {
      return inflightMatch.getResponse();
    }

    MulticastCache.store(this);

    const promise = this._fetch().catch((error) => {
      if (error.name === "AbortError") {
        const replacedInFlightMatch = MulticastCache.match(this);
        if (replacedInFlightMatch) {
          return replacedInFlightMatch.getResponse();
        }
      }
      throw error;
    });

    if (rolling && inflightMatch) {
      inflightMatch.abortRequest();
    }

    return promise;
  }

  private get _isRolling(): boolean {
    return this.opts.cache === "no-store" || this.opts.cache === "reload";
  }

  private get _isMulticastable(): boolean {
    return Object.prototype.hasOwnProperty.call(this.opts, "multicast")
      ? (this.opts.multicast as boolean)
      : !(this.opts.method === "POST" && this.opts.body);
  }
}

export default ApiRequest;

export  function request<T = unknown>(
  resourceUrl: string,
  opts?: IRequestOpts,
  abort?: unknown,
  tokenFromRenewal?: string,
  updateOptsAfterTokenRenewal?: ({ accessToken, token }: { accessToken: string, token: string }) => IRequestOpts,
) {
  if (
    (!isValidToken() || !isValidTokenAccess()) &&
    !tokenFromRenewal &&
    resourceUrl !== getToken &&
    !isTestMode() &&
    !opts?.omitToken
  ) {
   return renewRequestAfterGetToken(resourceUrl, opts, abort, updateOptsAfterTokenRenewal) as T;
  }

  const req = new ApiRequest(resourceUrl, opts, tokenFromRenewal);
  req.run();
  const promise = req.getResponse();
  const abortFunc = () => req.abortRequest();
  const promiseProcessed: unknown =
    opts && opts.parseJSON === false
      ? promise
      : promise.then(
          (res) => (<Response>res).json(),
          (reject: any) => {
            const { method: rejectMethod, resourceUrl: rejectResourceUrl } =
              reject || {};
            if (
              reject &&
              reject.response &&
              reject.response.status === 401 &&
              rejectResourceUrl !== getToken
            ) {
              const identifier = `${rejectMethod}-${rejectResourceUrl}`;
              if (
                rejectMethod &&
                rejectResourceUrl &&
                !notAuthorizedUrlsSet.has(identifier)
              ) {
                notAuthorizedUrlsSet.add(identifier);
                return renewRequestAfterGetToken(resourceUrl, opts, abort, updateOptsAfterTokenRenewal);
              }
            }
            throw reject;
          }
        );

  return (abort ? [promiseProcessed as T, abortFunc] : (promiseProcessed as T)) as T;
}



const renewRequestAfterGetToken = (
  resourceUrl: string,
  opts?: IRequestOpts,
  abort?: unknown,
  updateOpts?: ({ accessToken, token }: { accessToken: string, token: string }) => IRequestOpts,
): unknown => {
  return renewAfterGetToken(
    resourceUrl,
    (tokenFromRenewal:string, { accessToken, token }: { accessToken: string, token: string }) =>
      request(
        resourceUrl,
        updateOpts ? updateOpts({ accessToken, token }) : opts,
        abort,
        tokenFromRenewal,
        updateOpts
      ),
    isMSIQuery(resourceUrl)
  );
};



const getTokenInfo = (): string => {
  const token = jsonParse<ILSToken>(storage.getItemUnprocessed(storageNames.token)) as ILSToken;

  if (!token?.timeExpiration) {
    return "no token time expiration info";
  }

  const now = Date.now();

  const hasExpired = Date.now() > token.timeExpiration;

  return `Token info: ${
    hasExpired ? "token expired" : "token not expired"
  } - timeExpiration - ${token.timeExpiration}, now - ${now}`;
};
