import { FC, memo, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";
import GtmEvent from "web/Layout/Gtm/GtmEvent";
import __ from "web/Layout/Translations";

import withScrollToTop from "web/Components/Common/withScrollToTop/withScrollToTop";

import {
  hasBenefitsWithPdfForms,
  hasBenefitsWithReceiverForms,
} from "web/utils/page/product/subscription/hasForms";
import setBenefitGroupTypeParam from "web/utils/page/product/subscription/setBenefitGroupTypeParam";
import getPageReferrer from "web/utils/system/GTM/getPageReferrer";
import redirectToBrowserSettingsError from "web/utils/system/storage/storage/redirectToBrowserSettingsError";
import getSearchParameter from "web/utils/system/url/getSearchParameter";

import { BENEFIT_GROUP_TYPE } from "web/constants/benefits";
import urls from "web/constants/urls";

import { SubscriptionGroups } from "web/types/Subscription";
import { isFetchBaseQueryError } from "web/types/Utils";

import { setBenefitGroupType } from "web/features/subscriptionQuote/subscriptionQuoteSlice";
import { useGetCurrentUserSubscriptionQuery } from "web/features/subscriptions/subscriptionsApiSlice";

import PaymentsSummary from "../PaymentsSummary";
import PaymentsSummaryExtended from "../PaymentsSummaryExtended";
import AdditionalCarousel from "./AdditionalCarousel/additionalCarousel";
import RequiredActivities from "./RequiredActivities/requiredActivities";
import SuccessList from "./SuccessList";
import WindowDatesInfo from "./WindowDatesInfo";
import classes from "./success.scss";

let setLanguage = "pl_PL";
try {
  setLanguage = localStorage.getItem("language") || "pl_PL";
} catch (error) {
  redirectToBrowserSettingsError();
}
const bannerClassName =
  setLanguage === "pl_PL" ? classes.banner : classes.banner_EN;

const CheckoutSubscriptionSuccess: FC = memo(() => {
  let summary;

  const dispatch = useDispatch();
  const history = useHistory();
  const { search, pathname } = useLocation();
  const chosenBenefitGroupType = getSearchParameter({
    name: BENEFIT_GROUP_TYPE,
    search,
  }) as SubscriptionGroups;

  dispatch(
    setBenefitGroupType({
      benefitGroupType: chosenBenefitGroupType,
    })
  );

  const { data, isLoading, isFetching, error } =
    useGetCurrentUserSubscriptionQuery(undefined, {
      selectFromResult: ({ data, isLoading, isFetching, error }) => ({
        data: data?.find(
          (group) => group.benefitGroupType === Number(chosenBenefitGroupType)
        ),
        isLoading,
        isFetching,
        error,
      }),
      // TODO: Check if invalidating tag could be used instead of hard refetch - todo after implementing RTK in configurator
      refetchOnMountOrArgChange: true,
    });

  if (isFetchBaseQueryError(error) && error.status === 404) {
    history.push({
      pathname: urls.checkoutSubscriptionConfiguration,
      search: setBenefitGroupTypeParam(chosenBenefitGroupType),
    });
    return null;
  }

  const gtmOptions = useMemo(
    () => ({
      cgroup1: "Koszyk",
      cgroup2: "Zapisano świadczenia",
      page_referrer: getPageReferrer({ search, pathname }),
    }),
    [search, pathname, getPageReferrer]
  );

  const payments = (() => {
    const { employerTaxAmount, pointsBanks } = data || {};
    const {
      employeePriceSummary,
      employeePayUPriceSummary,
      employerPriceSummary,
    } = data?.totals || {};

    return chosenBenefitGroupType === "2" ? (
      <PaymentsSummaryExtended
        title="Płatności"
        employeePrice={employeePriceSummary || "0"}
        employeePayUPrice={employeePayUPriceSummary || "0"}
        employerTaxAmount={employerTaxAmount || "0"}
        pointsBanks={pointsBanks}
        isFetching={isFetching}
      />
    ) : (
      <PaymentsSummary
        employeePrice={employeePriceSummary || "0"}
        employerPrice={employerPriceSummary || "0"}
        employerTaxAmount={employerTaxAmount || "0"}
        isFetching={isFetching}
      />
    );
  })();

  switch (true) {
    case !!error: {
      console.error(error);
      summary = <ErrorComponent />;
      break;
    }
    case !!isLoading || !data: {
      summary = <Loading />;
      break;
    }
    default: {
      const hasReceiverFormsToFill = hasBenefitsWithReceiverForms(
        data || null,
        true
      );
      const hasPdfBarcodes = hasBenefitsWithPdfForms(data || null, true);
      const hasAnyFormsToFill = hasReceiverFormsToFill || hasPdfBarcodes;
      const isTotalPriceGreaterThanZero =
        data?.totals?.totalPriceSummary &&
        parseFloat(data.totals.totalPriceSummary) > 0;

      summary = (
        <>
          <section className={classes.desc}>
            {!hasAnyFormsToFill ? (
              <p className={classes["text--emphasized"]}>
                {__("Pomyślnie zakończyłeś wybór abonamentów.")}
              </p>
            ) : (
              data && (
                <RequiredActivities
                  hasReceiverFormsToFill={hasReceiverFormsToFill}
                  hasPdfBarcodes={hasPdfBarcodes}
                  data={data}
                />
              )
            )}
            <Link
              to={urls.customerSubscriptions}
              className={classes.button}
              data-t1="proceedToMySubscriptions"
            >
              {__("Przejdź do Moich abonamentów")}
            </Link>
          </section>
          <div className="col col-9">
            {isTotalPriceGreaterThanZero ? payments : null}
            {data && <SuccessList data={data} />}
          </div>
        </>
      );
    }
  }

  return (
    <>
      <div className={classes.wrapper}>
        <div className={bannerClassName} />
        {chosenBenefitGroupType === "2" && <WindowDatesInfo />}
        {summary}
        <AdditionalCarousel />
      </div>
      <GtmEvent options={gtmOptions} withUrl />
    </>
  );
});

export default withScrollToTop(CheckoutSubscriptionSuccess);
