/* eslint-disable @typescript-eslint/ban-types */
import handleTooManyTokenRenewals from "web/api/handleTooManyTokenRenewals";

import getToken from "web/utils/system/essentials/getToken";

// this countera are in the main thread so we have control over numebr of renewal action
const requestCounters: Record<string, number> = {};

export const renewAfterGetToken = async <T extends Function>(
  id: string,
  cb: T,
  isMSIQuery: boolean
) => {
  // count renewals for the same request
  if (requestCounters[id] > 3) {
    return handleTooManyTokenRenewals(id);
  }
  if (requestCounters.hasOwnProperty(id)) {
    requestCounters[id] += 1;
  } else {
    requestCounters[id] = 1;
  }

  const { token, accessToken } = (await getToken()) || {};

  // reset counter on succesful getToken
  if (token && accessToken) {
    requestCounters[id] = 1;
  }

  return cb(isMSIQuery ? accessToken : token, { token, accessToken });
};

export default renewAfterGetToken;
