import { number, string } from "prop-types";

import __ from "web/Layout/Translations";

import formatNumber from "web/utils/data/parser/number/formatNumber";

const ProvisionRow = ({
  rechargeCommission,
  rechargeCommissionPayer,
  className,
  label,
  type,
}) => {
  const provision = `${formatNumber(rechargeCommission)} ${__("pkt")}`;

  return (
    <>
      {rechargeCommissionPayer && rechargeCommissionPayer === "EMPLOYEE" ? (
        <div
          className={className}
          style={{ marginBottom: type === "CHARGE" ? 30 : 0 }}
        >
          <p>{label}</p>
          <p>{provision}</p>
        </div>
      ) : null}
    </>
  );
};

ProvisionRow.propTypes = {
  type: string,
  rechargeCommission: number,
  rechargeCommissionPayer: string,
  className: string,
  label: string,
};

ProvisionRow.defaultProps = {
  type: "",
  rechargeCommission: null,
  rechargeCommissionPayer: "",
  className: "",
  label: "",
};

export default ProvisionRow;
