import { Formik } from "formik";
import { number, string } from "prop-types";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import __ from "web/Layout/Translations";

import Button from "web/Components/Common/Button";
import ValidationErrorMessage from "web/Components/Common/ValidationErrorMessage";

import { setNotificationError } from "web/features/app/appSlice";

import AsideContainer from "./AsideContainer";
import DateCardNumberRow from "./DateCardNumberRow";
import Field from "./Field";
import FieldContainer from "./FieldContainer/fieldContainer";
import IssuanceFeeRow from "./IssuanceFeeRow/issuanceFeeRow";
import Points from "./Points";
import ProvisionRow from "./ProvisionRow/provisionRow";
import classes from "./prepaidCardAside.scss";

const errorMessageTotalPoints =
  "Masz za mało punktów na pokrycie opłaty za kartę i jej zasilenie.";

const inputValidate = (values) => {
  const errors = {};
  const reg = /^(?:\d|(?:[1-9]\d*))(?:[.,]\d{1,2})?$/;
  if (!reg.test(values.amount)) {
    errors.amount = __(`Podaj prawidłową kwotę`);
  }
  if (Number(values.amount) <= 0) {
    errors.amount = __(`Kwota musi być powyżej 0`);
  }
  if (!values.amount) {
    errors.amount = __("Pole jest wymagane.");
  }

  return errors;
};

const PrepaidCardAside = ({
  bankTotalPoints,
  id,
  type,
  chargeDeadlineDate,
  cardNumber,
  issuanceFee,
  issuanceFeePayer,
  rechargeCommission,
  rechargeCommissionPayer,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const smallTitle =
    type === "CREATE"
      ? __("WYDANIE NOWEJ KARTY I JEJ ZASILENIE:")
      : __("ZASILENIE KARTY O NUMERZE:");

  const provision =
    rechargeCommissionPayer && rechargeCommissionPayer !== "COMPANY"
      ? rechargeCommission
      : 0;

  const issuanceFeeNumber =
    issuanceFee && issuanceFeePayer === "EMPLOYEE" && type === "CREATE"
      ? issuanceFee
      : 0;

  const pointsToPay = (amount) => {
    const amountWithDot = amount.replace(",", ".");
    return Number(amountWithDot) + provision + issuanceFeeNumber;
  };

  return (
    <Formik
      initialValues={{ amount: "" }}
      validate={inputValidate}
      onSubmit={(values) => {
        const amount = values.amount.replace(",", ".");
        if (pointsToPay(amount) > bankTotalPoints) {
          dispatch(
            setNotificationError({ message: __(errorMessageTotalPoints) })
          );
        } else {
          localStorage.setItem(
            "PREPAID_CARD_DATA",
            JSON.stringify({
              price: Number(amount),
              id,
            })
          );
          history.push("/checkout-external/prepaid-card");
        }
      }}
    >
      {({ handleSubmit, values, errors }) => (
        <form onSubmit={handleSubmit}>
          <>
            <AsideContainer>
              <div>
                <p className={classes.smallText}>{smallTitle}</p>
                {type !== "CREATE" && (
                  <DateCardNumberRow
                    className={classes.normalTextBold}
                    cardNumber={cardNumber}
                    date={chargeDeadlineDate}
                  />
                )}
                <FieldContainer
                  label={__("Kwota zasilenia karty")}
                  currency={__("zł")}
                >
                  <Field
                    className={classes.field}
                    name="amount"
                    placeholder={__("Wpisz kwotę")}
                    field="amount"
                    showError={false}
                  />
                </FieldContainer>
                <div className={classes.errorMessage}>
                  {errors.amount && <ValidationErrorMessage name="amount" />}
                </div>
              </div>
              <>
                <ProvisionRow
                  label={__("Prowizja za zasilenie")}
                  className={classes.provisionContainer}
                  rechargeCommission={rechargeCommission}
                  rechargeCommissionPayer={rechargeCommissionPayer}
                  type={type}
                />
                <IssuanceFeeRow
                  label={__("Opłata za wydanie karty")}
                  className={classes.issuanceFeeContainer}
                  issuanceFee={issuanceFee}
                  issuanceFeePayer={issuanceFeePayer}
                  type={type}
                />
                <div
                  className={classes.amountContainer}
                  style={{
                    marginTop: issuanceFeeNumber || provision ? "26px" : "0",
                  }}
                >
                  <p>{__("Do zapłaty")}</p>
                  <Points points={pointsToPay(values.amount)} />
                </div>
              </>
            </AsideContainer>
            <div className={classes.buttonContainer}>
              <Button variant="tertiary" type="submit">
                {__("Przejdź dalej")}
              </Button>
            </div>
            <p
              className={classes.footer}
              data-t1="international_tourism_help_center_link"
            >
              {__("Masz pytanie? Sprawdź")}{" "}
              <Link className={classes.link} to="/contact-form">
                {__("Centrum pomocy")}
              </Link>
            </p>
          </>
        </form>
      )}
    </Formik>
  );
};

PrepaidCardAside.propTypes = {
  bankTotalPoints: number.isRequired,
  chargeDeadlineDate: string,
  cardNumber: string,
  id: number.isRequired,
  type: string,
  issuanceFeePayer: string,
  issuanceFee: number,
  rechargeCommission: number,
  rechargeCommissionPayer: string,
};

PrepaidCardAside.defaultProps = {
  issuanceFee: 0,
  issuanceFeePayer: "",
  type: "",
};

export default PrepaidCardAside;
