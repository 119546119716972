import { ArrowRight } from "@benefit-systems/common-components";
import { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

import classes from "./categoryTile.scss";

interface ICategoryTileProps {
  link: string;
}

const CategoryTile: FC<PropsWithChildren<ICategoryTileProps>> = ({
  link,
  children,
}) => {
  return (
    <Link className={classes.root} to={link}>
      {children}
      <ArrowRight className={classes.arrow} />
    </Link>
  );
};

export default CategoryTile;
