import { FC } from "react";

import { IHelpCenterCategory } from "web/types/HelpCenter";

import LinksBanner from "./LinksBanner";
import classes from "./categories.scss";

interface IHelpCenterDesktopCategoriesProps {
  categories: IHelpCenterCategory[];
}

const HelpCenterDesktopCategories: FC<IHelpCenterDesktopCategoriesProps> = ({
  categories,
}) => {
  return (
    <aside>
      <ul>
        {categories.map(({ id, name, image, children }) => {
          if (!children) return null;

          return (
            <li key={id} className={classes.item}>
              <LinksBanner title={name} sections={children} image={image} />
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default HelpCenterDesktopCategories;
