import { FC } from "react";
import { useSelector } from "react-redux";

import MobileHeader from "web/Layout/MobileHeader";
import __ from "web/Layout/Translations";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import urls from "web/constants/urls";

import { IHelpCenterCategory } from "web/types/HelpCenter";

import Category from "./Category";
import HelpButton from "./HelpButton";
import HelpCenterMobileSearchSection from "./SearchSection";
import classes from "./helpCenter.scss";

interface IHelpCenterMobileProps {
  categories: IHelpCenterCategory[];
}

const HelpCenterMobile: FC<IHelpCenterMobileProps> = ({ categories }) => {
  const { help_center_prefix: prefix } = useSelector(
    (state) => state.app.storeConfig
  );

  const { isParamLangEn } = useLangFromParams();

  return (
    <section>
      <MobileHeader replacementPath={`${urls.customer}#settings`}>
        {isParamLangEn ? "Help center" : __("Centrum pomocy")}
      </MobileHeader>
      <HelpCenterMobileSearchSection />
      <div className={classes.wrapper}>
        <h1 className={classes.headline}>
          {isParamLangEn ? "KnowledgeBase" : __("Baza wiedzy")}
        </h1>
        <ul className={classes.categories}>
          {categories?.map(({ id, name, children }) => {
            if (!children?.length) return null;

            return (
              <Category
                key={id}
                label={name}
                topics={children}
                prefix={prefix}
              />
            );
          })}
        </ul>
      </div>
      <HelpButton />
    </section>
  );
};

export default HelpCenterMobile;
