import { number, string } from "prop-types";

import __ from "web/Layout/Translations";

import formatNumber from "web/utils/data/parser/number/formatNumber";

const IssuanceFeeRow = ({
  label,
  issuanceFee,
  issuanceFeePayer,
  className,
  type,
}) => {
  return (
    <>
      {issuanceFee && issuanceFeePayer === "EMPLOYEE" && type === "CREATE" ? (
        <div className={className} style={{ marginTop: "14px" }}>
          <p>{label}</p>
          <p>
            {formatNumber(issuanceFee)} {__("pkt")}
          </p>
        </div>
      ) : null}
    </>
  );
};

IssuanceFeeRow.propTypes = {
  className: string,
  label: string,
  issuanceFee: number,
  issuanceFeePayer: string,
  type: string.isRequired,
};

IssuanceFeeRow.defaultProps = {
  className: "",
  label: "Opłata",
  issuanceFee: null,
  issuanceFeePayer: null,
};

export default IssuanceFeeRow;
