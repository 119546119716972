// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-root-BNa{margin-bottom:10px}.category-subcategoriesList-bXS{display:flex;flex-direction:column;gap:8px}`, "",{"version":3,"sources":["webpack://./web/Pages/HelpCenter/Mobile/Category/category.scss"],"names":[],"mappings":"AAIuB,mBACrB,kBAAA,CAGF,gCACE,YAAA,CACA,qBAAA,CACA,OAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  margin-bottom: 10px;\n}\n\n.subcategoriesList {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `category-root-BNa`,
	"subcategoriesList": `category-subcategoriesList-bXS`
};
export default ___CSS_LOADER_EXPORT___;
