import { FC } from "react";

import __ from "web/Layout/Translations";

import withScrollToTop from "web/Components/Common/withScrollToTop/withScrollToTop";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import { IHelpCenterCategory } from "web/types/HelpCenter";

import Breadcrumbs from "../Shared/Breadcrumbs";
import HelpCenterCategories from "./Categories/categories";
import HelpCenterDesktopSearchSection from "./SearchSection/searchSection";
import classes from "./helpCenter.scss";

interface IHelpCenterDesktopProps {
  categories: IHelpCenterCategory[];
}

const HelpCenterDesktop: FC<IHelpCenterDesktopProps> = ({ categories }) => {
  const { isParamLangEn } = useLangFromParams();

  const pageTitle = isParamLangEn ? "Knowledge base" : __("Baza wiedzy");
  return (
    <section className={`container ${classes.container}`}>
      <Breadcrumbs />
      <HelpCenterDesktopSearchSection />
      <h1 className={classes.title}>{pageTitle}</h1>
      <HelpCenterCategories categories={categories} />
    </section>
  );
};

export default withScrollToTop(HelpCenterDesktop);
