import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import simpleApiRequest from "./simpleApiRequest";

const getMimeType = (extension: string) => {
  switch (true) {
    case ["png", "jpg", "jpeg"].includes(extension):
      return "image/*";
    case ["avi", "mp4", "mov"].includes(extension):
      return "video/*";
    case extension === "pdf":
      return "application/pdf";
    default:
      return "";
  }
};

const downloadFile = async (
  source: string,
  fileName: string,
  isApiSource = false,
  extension?: string,
  openInNewTab = false
) => {
  const extensionProcessed = extension ?? source?.split(".").pop();
  const fileNameParsed = fileName?.replace(".", "_");

  try {
    const response = await simpleApiRequest(source, {
      responseType: "arraybuffer",
    });
    const file = await response.arrayBuffer();
    const blob = new Blob([file], {
      type: isApiSource
        ? `${response.headers.get("content-type")}`
        : getMimeType(extensionProcessed ?? ""),
    });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileNameParsed);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    if (openInNewTab) {
      window.open(url, "_blank");
    }
  } catch (error) {
    newRelicErrorReport(error, "web-app/web/api/downloadFile.js - 41");
    console.error(error);
  }
};

export default downloadFile;
