import { string } from "prop-types";
import { useMemo } from "react";

import classes from "./dateBox.scss";

const DateBox = ({ label, date, className }) => {
  const dateValue = useMemo(
    () =>
      new Date(date).toLocaleDateString("pl-PL", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
    [date]
  );

  return (
    <div className={`${classes.date} ${className}`}>
      <p>
        {label} <span>{dateValue}</span>
      </p>
    </div>
  );
};

DateBox.propTypes = {
  label: string,
  date: string,
  className: string,
};

DateBox.defaultProps = {
  className: "",
  label: "",
};

export default DateBox;
