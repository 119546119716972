import { FC } from "react";
import { Link } from "react-router-dom";

import __ from "web/Layout/Translations";

import { IHelpCenterCategoryArticle } from "web/types/HelpCenter";

import classes from "./anchor.scss";

interface IHelpCenterDesktopAnchorProps {
  title: IHelpCenterCategoryArticle<"ShortHelpCenterArticle">["title"];
  url: IHelpCenterCategoryArticle<"ShortHelpCenterArticle">["url_key"];
}

const HelpCenterDesktopAnchor: FC<IHelpCenterDesktopAnchorProps> = ({
  title,
  url,
}) => {
  return (
    <Link to={url} className={classes.link}>
      {__(title)}
    </Link>
  );
};

export default HelpCenterDesktopAnchor;
