import { isEmpty } from "lodash";
import {
  FC,
  PropsWithChildren,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import __ from "web/Layout/Translations";

import { IApiRequestMethods } from "web/api/apiRequestTypes";

import sanitizeObject from "web/utils/data/parser/object/sanitizeObject";
import getFormConfig from "web/utils/page/product/subscription/forms/getFormConfig";
import { isExternalForm } from "web/utils/page/product/subscription/forms/getFormMode";
import insertSusbscriptionNameToMessage from "web/utils/page/product/subscription/forms/insertSusbscriptionNameToMessage";
import validateUniquePesel from "web/utils/page/product/subscription/forms/validateUniquePesel";
import setBenefitGroupTypeParam from "web/utils/page/product/subscription/setBenefitGroupTypeParam";
import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import restUrls from "web/constants/restUrls";
import { CheckoutTypes, checkoutSteps } from "web/constants/urls";

import {
  ISubscriptionDraftForms,
  ISubscriptionReceiverFormField,
  ISubscriptionTotals,
  RequirePeselValue,
  SubscriptionCheckoutSteps,
  SubscriptionCheckoutStepsTemplateProps,
  SubscriptionCollectedDataScope,
  SubscriptionFormReceiver,
} from "web/types/Subscription";
import { Nullable } from "web/types/Utils";

import { request } from "web/api";

type Params = {
  uuid: string;
};

interface IWrappedWithProps {
  initialDraft?: Nullable<ISubscriptionDraftForms>;
  totals: ISubscriptionTotals;
  prevStep: () => void;
  children?: ReactNode | undefined;
}

export interface IFormConfig {
  external: boolean;
  ignoreValidate?: boolean;
  receiverId: string;
  receiverType: SubscriptionFormReceiver;
  required: boolean;
  subscriptionId: string;
  validateUniquePesel: Nullable<ISubscriptionReceiverFormField>;
}

interface IFormsConfig {
  [formId: string]: IFormConfig;
}

interface IFormFields {
  [fieldId: string]: string | RequirePeselValue;
}

export interface IFormsData {
  [formId: string]: {
    fields?: IFormFields;
    receiverId?: string;
    updated?: boolean;
    subscriptionId: string;
  };
}

interface IFormsPayload {
  receiverId: string;
  receiver: SubscriptionFormReceiver;
  receiverEmail?: string;
  fields?: IFormFields;
}

interface IFormsContext {
  formsData: IFormsData;
  formsError: {
    [formId: string]: {
      error: boolean;
    };
  };
  saveForm: ({
    formId,
    currentValues,
    receiverId,
    updated,
    subscriptionId,
  }: {
    formId: string;
    currentValues: IFormFields;
    receiverId: string;
    updated?: boolean;
    subscriptionId: string;
  }) => void;
}

const DEFAULT_ERROR_MESSAGE =
  "W formularzu znajduje się błąd. Popraw go i spróbuj ponownie.";
const EMPTY_FORM_ERROR_MESSAGE = "Dane w formularzu nie zostały uzupełnione.";
const UNIQUE_PESEL_ERROR_MESSAGE =
  "W formularzu świadczenia {{subscription}} znajduje się błąd. Numery PESEL nie mogą się powtarzać.";

export const InformationStepCtx = createContext<Nullable<IFormsContext>>(null);

export const useInformationStepContext = () => {
  const ctx = useContext(InformationStepCtx);

  useEffect(() => {
    if (!ctx)
      throw new Error(
        "useInformationStepContext hook used outside of InformationStepContext"
      );
  }, [ctx]);

  return ctx;
};

const getPayload = (formsData: IFormsData, formsConfig: IFormsConfig) => {
  if (isEmpty(formsData)) return {};
  const payload: IFormsPayload[] = [];

  Object.keys(formsData).forEach((formId) => {
    const { external, receiverType } = formsConfig[formId];
    const { fields, receiverId } = formsData[formId];

    if (isEmpty(fields) || !receiverId) return;

    const sanitizedFields = sanitizeObject(fields);

    payload.push({
      receiverId,
      receiver: receiverType,
      ...(external
        ? { receiverEmail: fields.receiverEmail as string }
        : { fields: sanitizedFields }),
    });
  });

  return payload;
};

const withSubscriptionCheckoutInformationStepContext =
  (
    WrappedComponent: FC<
      PropsWithChildren<SubscriptionCheckoutStepsTemplateProps>
    >
  ) =>
  ({ initialDraft, ...props }: IWrappedWithProps) => {
    const { benefitGroupType } = useSelector(
      (state) => state.subscriptionQuote
    );

    const { uuid } = useParams<Params>();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | boolean>(false);
    const [formsData, setFormsData] = useState<IFormsData>({});
    const [formsConfig, setFormsConfig] = useState<IFormsConfig>({});
    const [formsError, setFormsError] = useState({});

    const saveForm = useCallback(
      ({
        formId,
        currentValues,
        receiverId,
        subscriptionId,
        updated = false,
      }: {
        formId: string;
        currentValues: IFormFields;
        receiverId: string;
        subscriptionId: string;
        updated?: boolean;
      }) => {
        setFormsData((prevForms) => {
          return {
            ...prevForms,
            [formId]: {
              ...(updated
                ? { updated, subscriptionId }
                : { fields: currentValues, receiverId, subscriptionId }),
            },
          };
        });
      },
      []
    );

    useEffect(() => {
      if (!initialDraft) return;

      const config: IFormsConfig = {};

      initialDraft.benefits.forEach((subscription) => {
        const { benefit, id: subscriptionId, receivers } = subscription;
        const { wayChooseOthersInActiveForm, collectedDataScope } = benefit;

        if (collectedDataScope === SubscriptionCollectedDataScope.NULL) return;

        receivers.forEach((receiver) => {
          const { form, id: receiverId, type: receiverType } = receiver;

          if (typeof form !== "object") return;

          const { id: formId, type: formType } = form;
          const external = isExternalForm(
            formType,
            receiverType,
            wayChooseOthersInActiveForm
          );

          config[formId] = {
            ...getFormConfig(form, receiverType, external),
            external,
            receiverId,
            receiverType,
            subscriptionId,
          };
        });
      });

      setFormsConfig(config);
    }, [initialDraft]);

    const setGlobalFormError = (
      error: string | boolean = DEFAULT_ERROR_MESSAGE
    ) => {
      if (typeof error === "boolean") {
        setError(error);
      } else if (typeof error === "string") {
        setError(__(error));
      }
    };

    const handleSubmitInformationForms = useCallback(async () => {
      let errors: string | boolean = false;
      // WHOLE STEP VALIDATION
      Object.keys(formsConfig).forEach((formId) => {
        const config = formsConfig[formId];

        const formError =
          // if required - check if form is filled in current configuration process
          config.required && !formsData.hasOwnProperty(formId);
        const isUnique = validateUniquePesel(config, formsData);

        // Set global error message
        if (!isUnique)
          errors = insertSusbscriptionNameToMessage(
            config.subscriptionId,
            UNIQUE_PESEL_ERROR_MESSAGE,
            initialDraft
          );
        if (formError) errors = EMPTY_FORM_ERROR_MESSAGE;

        setFormsError((prevErrors) => {
          return {
            ...prevErrors,
            [formId]: { error: formError },
          };
        });
      });

      setGlobalFormError(errors);

      if (errors) return false;

      const payload = getPayload(formsData, formsConfig);

      setLoading(true);

      try {
        const response = await request(
          `${restUrls.employeeSubscriptionDraftReceiverForms.replace(
            ":uuid",
            uuid
          )}`,
          {
            method: IApiRequestMethods.POST,
            body: JSON.stringify(payload),
          }
        );

        if (response) {
          history.push(
            `/checkout/${CheckoutTypes.SUBSCRIPTION}/${
              checkoutSteps.agreements
            }/${uuid}${setBenefitGroupTypeParam(benefitGroupType)}`
          );
        }
      } catch (errorData) {
        setError(__("Błąd pobierania danych."));
        newRelicErrorReport(
          errorData,
          "web-app/web/Pages/Checkout/Subscription/InformationStep/withContext.tsx"
        );
        setLoading(false);
      }

      return null;
    }, [formsConfig, formsData]);

    return (
      <InformationStepCtx.Provider
        value={{
          formsData,
          formsError,
          saveForm,
        }}
      >
        <WrappedComponent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          step={SubscriptionCheckoutSteps.INFORMATIONS}
          totalsIsLoading={loading}
          totalsError={error}
          nextStep={handleSubmitInformationForms}
        />
      </InformationStepCtx.Provider>
    );
  };

export default withSubscriptionCheckoutInformationStepContext;
