/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit";

import { IApiRequestMethods } from "web/api/apiRequestTypes";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import restUrls from "web/constants/restUrls";

import { ISubscriptionFilterBenefitGroups } from "web/types/Subscription";

import { request } from "web/api";
import { RootState } from "web/store";

export const getFilteredBenefitGroups = createAsyncThunk(
  "benefitGroups/getFilteredBenefits",
  async ({ skuArray }: any, { getState }) => {
    const { benefitGroups } = getState() as RootState;

    if (!isArrayHasItems(skuArray)) {
      return {
        benefitGroups: benefitGroups ?? {},
      };
    }

    const cacheKey = JSON.stringify(skuArray);

    // skip request if data is in cache
    const benefitGroupsFromCache = benefitGroups.cache[cacheKey];
    if (benefitGroupsFromCache) {
      return {
        benefitGroups: JSON.parse(benefitGroupsFromCache),
      };
    }
    try {
      const response = (await request(restUrls.filterBenefitGroupsEmployee, {
        method: IApiRequestMethods.POST,
        body: JSON.stringify({
          benefitGroupIds: [...skuArray],
        }),
      })) as ISubscriptionFilterBenefitGroups;

      return {
        cacheKey,
        benefitGroups: response?.benefitGroups,
      };
    } catch (err) {
      newRelicErrorReport(err, "benefitGroups - getFilteredBenefitGroups");
      throw err;
    }
  }
);
