import { object } from "prop-types";

import Description from "web/Pages/Product/Shared/Description";

import __ from "web/Layout/Translations";

import { useAppContext } from "web/context/app";

import classes from "./prepaidCardDescription.scss";

const PrepaidCardDescription = ({ product }) => {
  const { isMobile } = useAppContext();

  return (
    <>
      {product.description && !isMobile ? (
        <Description
          className={classes.row}
          label={__("Opis")}
          text={product.description}
        />
      ) : null}
      {product.implementationRules && !isMobile && (
        <Description
          className={classes.row}
          label={__("Zasady realizacji")}
          text={product.implementationRules}
          id="implementationRules"
        />
      )}
      {product.methodAndDateOfCardDelivery && !isMobile ? (
        <Description
          className={classes.row}
          label={__("Sposób i termin dostarczenia karty")}
          text={product.methodAndDateOfCardDelivery}
          id="methodAndDateOfCardDelivery"
          dataT1="method_and_date_of_card_delivery"
        />
      ) : null}
    </>
  );
};

PrepaidCardDescription.propTypes = {
  product: object.isRequired,
};

export default PrepaidCardDescription;
