import DOMPurify from "dompurify";
import { object } from "prop-types";

import classesDesktop from "web/Pages/Product/Shared/Legals/legalsDesktop.scss";
import classesMobile from "web/Pages/Product/Shared/Legals/legalsMobile.scss";
import accordionClassesMobile from "web/Pages/Product/Shared/Legals/mobileAccordion.scss";

import __ from "web/Layout/Translations";

import Accordion from "web/Components/Common/Accordion";

import DownloadIcon from "web/assets/icons/download.svg";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { useAppContext } from "web/context/app";

const Legals = ({ product }) => {
  const { isMobile } = useAppContext();

  const accordionClasses = isMobile ? accordionClassesMobile : null;
  const classes = isMobile ? classesMobile : classesDesktop;

  if (
    product &&
    (!!product.supplierDescription ||
      !!product.complaintsPolicy ||
      !!product.supplierRegulations)
  ) {
    return (
      <section className={classes.root}>
        <span className={classes.headerHelper} />
        <span className={classes.title}>{__("Informacje Prawne")}</span>

        {product.contractWithdrawalContent ? (
          <Accordion
            className={classes.accordionRoot}
            label={__("Odstąpienie od umowy")}
            arrow
            classes={accordionClasses}
          >
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(product.contractWithdrawalContent),
              }}
            />
          </Accordion>
        ) : null}

        {product.supplierDescription ? (
          <Accordion
            className={classes.accordionRoot}
            label={__("Podmiot realizujący usługę")}
            arrow
            classes={accordionClasses}
          >
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(product.supplierDescription),
              }}
            />
          </Accordion>
        ) : null}

        {product.complaintsPolicy ? (
          <Accordion
            className={classes.accordionRoot}
            label={__("Reklamacje i odpowiedzialność")}
            arrow
            classes={accordionClasses}
            rootDataT1="complaints_policy"
          >
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(product.complaintsPolicy),
              }}
            />
          </Accordion>
        ) : null}

        {product?.supplierRegulations ||
        isArrayHasItems(product?.supplierRegulationsFiles) ? (
          <Accordion
            className={classes.accordionRoot}
            label={__("Regulamin podmiotu realizującego usługę")}
            arrow
            classes={accordionClasses}
            rootDataT1="supplier_regulations"
            rootDataT2={DOMPurify.sanitize(product.provider_regulations)}
          >
            {isArrayHasItems(product.supplierRegulationsFiles) ? (
              product.supplierRegulationsFiles.map(({ fileName, url }) => (
                <div key={url} className={classes.regulations}>
                  <h5 className={classes.regulations__heading}>
                    {fileName || "plik"}
                  </h5>
                  <a
                    href={url}
                    download={fileName || "plik"}
                    className={classes.regulations__link}
                  >
                    <span className={classes.regulations__linkText}>
                      {__("Pobierz plik")}
                    </span>
                    <DownloadIcon className={classes.regulations__icon} />
                  </a>
                </div>
              ))
            ) : (
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(product.supplierRegulations),
                }}
              />
            )}
          </Accordion>
        ) : null}
      </section>
    );
  }

  return null;
};

Legals.propTypes = {
  product: object.isRequired,
};

export default Legals;
