// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.productPrepaidCard-root-Yul{padding-top:30px}.productPrepaidCard-row-FLs{display:flex;align-items:stretch;justify-content:space-between}.productPrepaidCard-main-RFF{width:66%;overflow:hidden}.productPrepaidCard-aside-XUx{width:26%}.productPrepaidCard-reviews-VzT{margin:0 0 100px}`, "",{"version":3,"sources":["webpack://./web/Pages/Customer/Content/PrepaidCards/ProductPrepaidCard/productPrepaidCard.scss"],"names":[],"mappings":"AAIuB,6BACrB,gBAAA,CAGF,4BACE,YAAA,CACA,mBAAA,CACA,6BAAA,CAGF,6BACE,SAAA,CACA,eAAA,CAGF,8BACE,SAAA,CAGF,gCACE,gBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  padding-top: 30px;\n}\n\n.row {\n  display: flex;\n  align-items: stretch;\n  justify-content: space-between;\n}\n\n.main {\n  width: 66%;\n  overflow: hidden;\n}\n\n.aside {\n  width: 26%;\n}\n\n.reviews {\n  margin: 0 0 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `productPrepaidCard-root-Yul`,
	"row": `productPrepaidCard-row-FLs`,
	"main": `productPrepaidCard-main-RFF`,
	"aside": `productPrepaidCard-aside-XUx`,
	"reviews": `productPrepaidCard-reviews-VzT`
};
export default ___CSS_LOADER_EXPORT___;
