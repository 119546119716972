import { node } from "prop-types";

import classes from "./asideContainer.scss";

const AsideContainer = ({ children }) => {
  return (
    <div className={classes.root}>
      <div className={classes.top}>{children[0]}</div>
      <div className={classes.bottom}>{children[1]}</div>
    </div>
  );
};

AsideContainer.propTypes = {
  children: node.isRequired,
};

export default AsideContainer;
