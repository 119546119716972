import { Button } from "@benefit-systems/common-components";
import { Link } from "react-router-dom";

import { ImageSource } from "web/Layout/Common/Image";
import __ from "web/Layout/Translations";

import AstroImg from "web/assets/images/astroImg.png";

import classes from "./mobilePlug.scss";

const MobilePlug = () => {
  return (
    <div className={classes.root}>
      <ImageSource src={AstroImg} />
      <h2>{__("Karty przedpłacone nie są obsługiwane w wersji mobilnej.")}</h2>
      <p>
        {__(
          "Pracujemy nad tym. Obecnie możesz skorzystać z kart przedpłaconych na komputerze."
        )}
      </p>
      <Link to="/" className={classes.link}>
        <Button variant="tertiary" type="submit">
          {__("Przejdź do strony głównej")}
        </Button>
      </Link>
    </div>
  );
};

export default MobilePlug;
