import { FC } from "react";
import { useSelector } from "react-redux";

import { ImageSource } from "web/Layout/Common/Image";
import __ from "web/Layout/Translations";

import getSrcMedia from "web/utils/system/url/getSrcMedia";

import { IHelpCenterCategory } from "web/types/HelpCenter";

import LinksContainer from "./LinksContainer";
import classes from "./linksBanners.scss";

interface IHelpCenterDesktopLinksBannerProps {
  title: IHelpCenterCategory["name"];
  sections: IHelpCenterCategory["children"];
  image: IHelpCenterCategory["image"];
}

const HelpCenterDesktopLinksBanner: FC<IHelpCenterDesktopLinksBannerProps> = ({
  title,
  sections,
  image = null,
}) => {
  const storeConfig = useSelector(({ app }) => app.storeConfig);
  const setItemClass = () => {
    if (sections?.length > 2) {
      return classes.itemMargin;
    }
    if (sections?.length === 1 && !image) {
      return classes.itemLong;
    }
    return classes.item;
  };

  const isDisplayedWithImage = sections?.length === 1 && image;

  const imageProcessedSrc = isDisplayedWithImage
    ? getSrcMedia({
        storeConfig,
        src: image,
        isHelpCenter: true,
      })
    : null;

  const itemClass = setItemClass();
  return (
    <div className={classes.root}>
      <div className={classes.title}>{__(title)}</div>
      <div className={classes.card}>
        {sections && (
          <ul className={classes.list}>
            {sections?.map((section) => (
              <li key={section.id} className={itemClass}>
                <LinksContainer section={section} />
              </li>
            ))}
          </ul>
        )}
        {isDisplayedWithImage && (
          <div className={classes.imageContainer}>
            <ImageSource
              src={imageProcessedSrc}
              className={classes.image}
              alt="banner"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HelpCenterDesktopLinksBanner;
