import { string } from "prop-types";

import __ from "web/Layout/Translations";

import DateBox from "../DateBox";

const DateCardNumberRow = ({ cardNumber, className, date }) => {
  return (
    <div>
      <p className={className}>{cardNumber}</p>
      {date && (
        <DateBox date={date} label={__("Termin możliwości zasilenia karty:")} />
      )}
    </div>
  );
};

DateCardNumberRow.propTypes = {
  cardNumber: string.isRequired,
  className: string,
  date: string,
};

DateCardNumberRow.defaultProps = {
  className: "",
};

export default DateCardNumberRow;
