import { motion } from "framer-motion";
import { useState } from "react";

import __ from "web/Layout/Translations";

import ChatMark from "web/assets/icons/chat-round-mark.svg";
import CloseMark from "web/assets/icons/close-round-mark.svg";
import MailMark from "web/assets/icons/mail-round-mark.svg";
import QuestionMark from "web/assets/icons/question-round-mark.svg";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import classes from "./helpButton.scss";

const HelpCenterMobileHelpButton = () => {
  const [isExpanded, setExpanded] = useState(false);

  const { isParamLangEn } = useLangFromParams();

  const animatableStylesName = isExpanded ? "open" : "close";
  const transition = { duration: 0.2 };
  const variants = {
    open: { width: "100%", height: "225px" },
    close: { width: "fit-content", height: "fit-content" },
  };
  const displayVariantsFirst = {
    open: { bottom: "160px", opacity: 1 },
    close: { bottom: "20px", opacity: 0 },
  };
  const displayVariantsSecond = {
    open: { bottom: "80px", opacity: 1 },
    close: { bottom: "20px", opacity: 0 },
  };

  const headerClickHandler = () => {
    setExpanded((prevState) => !prevState);
  };

  return (
    <div className={classes.root}>
      <motion.div
        className={classes.animate}
        initial={animatableStylesName}
        animate={animatableStylesName}
        transition={transition}
        variants={variants}
      >
        <motion.div
          className={classes.additionalButton}
          initial={animatableStylesName}
          animate={animatableStylesName}
          transition={transition}
          variants={displayVariantsFirst}
        >
          {isParamLangEn ? "Send message" : __("Wyślij wiadomość")}
          <MailMark className={classes.bigIcon} />
        </motion.div>
        <motion.div
          className={classes.additionalButton}
          initial={animatableStylesName}
          animate={animatableStylesName}
          transition={transition}
          variants={displayVariantsSecond}
        >
          {isParamLangEn ? "Chat" : __("Czat")}
          <ChatMark className={classes.bigIcon} />
        </motion.div>
        <div className={classes.buttonWrapper}>
          <button
            type="button"
            className={classes.button}
            onClick={headerClickHandler}
          >
            {isExpanded ? (
              <CloseMark className={classes.icon} />
            ) : (
              <QuestionMark className={classes.icon} />
            )}
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default HelpCenterMobileHelpButton;
