import restUrls from "web/constants/restUrls";

import { BankItems, BankResetItems } from "web/types/Banks";

import { AppDispatch } from "web/store";

import api from "../api/apiSlice";
import translateAndTransformBanks from "./utils/translateAndTransformBanks";

interface IPointsBank {
  items: BankItems;
  itemsActive: BankItems;
  itemsReset: BankResetItems;
  total: number;
}

export const pointsBankApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getBanks: builder.query<IPointsBank, void>({
      queryFn: async (_, arg, _extraOptions, fetchWithBQ) => {
        const dataBanks = (await fetchWithBQ(`${restUrls.pointsBank}`)) as {
          data?: IPointsBank;
          error?: { status: number; data: { message: string } };
        };

        if (dataBanks?.error) {
          return { error: dataBanks.error };
        }

        const { items } = (dataBanks?.data as { items: BankItems }) || {};

        const [{ items: itemsTransformed, total, itemsActive }, dataReset] =
          (await Promise.all([
            translateAndTransformBanks(items),
            fetchWithBQ(restUrls.resetPointsBank),
          ])) as [
            { items: BankItems; itemsActive: BankItems; total: number },
            { data: { items: BankResetItems } }
          ];

        const { items: itemsReset } = dataReset?.data || {};

        return {
          data: { items: itemsTransformed, itemsActive, itemsReset, total },
        };
      },
      providesTags: ["PointsBank"],
    }),
  }),
});

export const invalidateBanks = (dispatch: AppDispatch) =>
  dispatch(pointsBankApiSlice.util.invalidateTags(["PointsBank"]));
export const getBanksForce = (dispatch: AppDispatch) =>
  dispatch(
    pointsBankApiSlice.endpoints.getBanks.initiate(undefined, {
      forceRefetch: true,
    })
  );
export const { useGetBanksQuery } = pointsBankApiSlice;
