import { string } from "prop-types";

import classes from "./title.scss";

const Title = ({ title }) => {
  return (
    <div className={classes.root}>
      <h1 className={classes.title} data-t1="product_title" data-t2={title}>
        {title}
      </h1>
    </div>
  );
};

Title.propTypes = {
  title: string.isRequired,
};

export default Title;
