import { createSlice } from "@reduxjs/toolkit";

import { Error } from "web/api/apiRequestTypes";

import type { Nullable } from "web/types/Utils";

import { getFilteredBenefitGroups } from "./benefitGroupsThunks";

interface IFilterBenefitGroupsState {
  isLoading: boolean;
  cache: { [key: string]: string };
  benefitGroups: { [key: string]: { price: Nullable<number> } };
  error: Nullable<Error>;
}

const initialState: IFilterBenefitGroupsState = {
  benefitGroups: {},
  isLoading: false,
  cache: {},
  error: null,
};

export const filterBenefitGroupsSlice = createSlice({
  name: "benefitGroups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFilteredBenefitGroups.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFilteredBenefitGroups.fulfilled, (state, action) => {
      const { payload } = action;
      const { cacheKey } = payload;

      return {
        ...state,
        isLoading: false,
        benefitGroups: payload.benefitGroups,
        cache: {
          ...state.cache,
          [cacheKey as string]: JSON.stringify(payload.benefitGroups),
        },
      };
    });
  },
});

export default filterBenefitGroupsSlice.reducer;
