export type INewBenefitFieldsMap = {
  type: string,
  options?: string[],
  name: string,
  id: string,
  key: string,
  placeholder: string | undefined,
  label: string,
  rules: string[],
  initialValue: string,
  readonly: boolean,
  component?: string,
};

export type IBenefit = {
  category: string,
  placeName: string,
  province: string,
  city: string,
  webSite: string,
  phoneNumber: string,
  description: string,
  email: string,
  productId: number,
  benefitType: string,
  subject: string,
}

export const radioValues = {
  productInformation: "productInformation",
  newBenefit: "newBenefit",
};

export const param = "new";

export const productIdFieldShape = {
  type: "text",
  name: "productId",
  id: "productId",
  key: "productId",
  placeholder: "Wpisz ID produktu",
  label: "id produktu",
  rules: [],
  initialValue: "",
  readonly: false,
  component: "",
};

export const productDescriptionFieldShape = {
  type: "text",
  component: "textarea",
  name: "description",
  id: "description",
  key: "description",
  placeholder: "Opis...",
  label: "opis zgłoszenia",
  rules: ["required"],
  initialValue: "",
  readonly: false,
};

export const emailFieldShape = {
  type: "email",
  name: "email",
  id: "email",
  key: "email",
  placeholder: undefined,
  label: "twój e-mail",
  rules: ["required", "email"],
  initialValue: "",
  readonly: false,
  component: "",
};

export const newBenefitFieldsMap: INewBenefitFieldsMap[] = [
  {
    type: "select",
    options: [],
    name: "category",
    id: "category",
    key: "category",
    placeholder: undefined,
    label: "kategoria",
    rules: ["required"],
    initialValue: "",
    readonly: false,
  },
  {
    type: "text",
    name: "placeName",
    id: "placeName",
    key: "placeName",
    placeholder: undefined,
    label: "nazwa obiektu",
    rules: ["required"],
    initialValue: "",
    readonly: false,
  },
  {
    type: "select",
    options: [],
    name: "province",
    id: "province",
    key: "province",
    placeholder: undefined,
    label: "wybierz województwo",
    rules: [],
    initialValue: "",
    readonly: false,
  },
  {
    type: "text",
    name: "city",
    id: "city",
    key: "city",
    placeholder: undefined,
    label: "miasto",
    rules: ["required"],
    initialValue: "",
    readonly: false,
  },
  {
    type: "text",
    name: "webSite",
    id: "webSite",
    key: "webSite",
    placeholder: undefined,
    label: "strona www",
    rules: ["required"],
    initialValue: "",
    readonly: false,
  },
  {
    type: "text",
    name: "phoneNumber",
    id: "phoneNumber",
    key: "phoneNumber",
    placeholder: undefined,
    label: "telefon",
    rules: [],
    initialValue: "",
    readonly: false,
  },
  {
    type: "text",
    component: "textarea",
    name: "description",
    id: "description",
    key: "description",
    placeholder: "Opis...",
    label: "opis zgłoszenia",
    rules: ["required"],
    initialValue: "",
    readonly: false,
  },
  {
    type: "email",
    name: "email",
    id: "email",
    key: "email",
    placeholder: undefined,
    label: "twój e-mail",
    rules: ["required", "email"],
    initialValue: "",
    readonly: false,
  },
];
export const categoryFieldShape = {
  type: "select",
  options: [],
  name: "category",
  id: "category",
  key: "category",
  placeholder: "",
  label: "kategoria",
  rules: ["required"],
  initialValue: "",
  readonly: false,
  component: "",
};

export const benefitTypeFieldShape = {
  type: "select",
  options: [],
  name: "benefitType",
  id: "benefitType",
  key: "benefitType",
  placeholder: "",
  label: "rodzaj świadczenia",
  rules: ["required"],
  initialValue: "",
  readonly: false,
  component: "",
};

export const subjectFieldShape = {
  type: "select",
  options: [],
  name: "subject",
  id: "subject",
  key: "subject",
  placeholder: "",
  label: "zakres pomocy",
  rules: ["required"],
  initialValue: "",
  readonly: false,
  component: "",
};

export const productInformationFieldsAllMap: INewBenefitFieldsMap[] = [
  productIdFieldShape,
  productDescriptionFieldShape,
  emailFieldShape,
  categoryFieldShape,
  benefitTypeFieldShape,
  subjectFieldShape,
];

export const readMoreTypeConditions = {
  readMore: "readMore",
  seeDetails: "seeDetails",
};
