import {
  FC,
  PropsWithChildren,
  ReactElement,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";

import { SimpleFn } from "web/types/Common";
import { Nullable } from "web/types/Utils";

const enum BiometryLoginType {
  FACE_ID = "FaceID",
  TOUCH_ID = "TouchID",
  BIOMETRICS = "Biometrics",
}

export const enum OutcomingWebViewMessageType {
  LOGIN = "login",
  CHANGE_PIN = "change_pin",
  TOGGLE_BIOMETRY = "toggle_biometry",
  CONVERT_TO_PDF = "convert_to_pdf",
  REQUEST_REVIEW = "request_review",
}

interface OutcomingWebViewMessageBase {
  type: OutcomingWebViewMessageType;
  payload?: unknown;
}

type IOutcomingWebViewLoginMessagePayload = {
  userId: string;
  token: string;
  idToken: string;
};

interface IOutcomingWebViewLoginMessage extends OutcomingWebViewMessageBase {
  type: OutcomingWebViewMessageType.LOGIN;
  payload: IOutcomingWebViewLoginMessagePayload;
}

interface IOutcomingWebViewChangePinMessage
  extends OutcomingWebViewMessageBase {
  type:
    | OutcomingWebViewMessageType.TOGGLE_BIOMETRY
    | OutcomingWebViewMessageType.CHANGE_PIN;
  payload?: boolean;
}

type IOutcomingWebViewConvertToPdfMessagePayload = {
  html: string;
  pdfFileName: string;
};

interface IOutcomingWebViewConvertToPdfMessage
  extends OutcomingWebViewMessageBase {
  type: OutcomingWebViewMessageType.CONVERT_TO_PDF;
  payload: IOutcomingWebViewConvertToPdfMessagePayload;
}

interface IOutcomingWebViewRequestReviewMessage
  extends OutcomingWebViewMessageBase {
  type: OutcomingWebViewMessageType.REQUEST_REVIEW;
}

type OutcomingWebViewMessage =
  | IOutcomingWebViewLoginMessage
  | IOutcomingWebViewChangePinMessage
  | IOutcomingWebViewConvertToPdfMessage
  | IOutcomingWebViewRequestReviewMessage;

const enum IncomingWebViewMessageType {
  SET_BIOMETRY = "set_biometry",
  SET_IS_BIOMETRY_ENABLED = "set_is_biometry_enabled",
  SET_IS_REVIEW_REQUEST_ALLOWED = "set_is_review_request_allowed",
}

interface IWebViewMessageBase {
  type: IncomingWebViewMessageType;
  payload: unknown;
}

type WebViewBiometryMessagePayload = {
  isBiometryEnabled: boolean;
  biometryLoginType: Nullable<BiometryLoginType>;
};

type WebViewReviewCtx = {
  askForReview: SimpleFn;
} & WebViewBiometryMessagePayload;

interface IWebViewBiometryMessage extends IWebViewMessageBase {
  type: IncomingWebViewMessageType.SET_BIOMETRY;
  payload: WebViewBiometryMessagePayload;
}

interface IWebViewIsBiometryEnabledMessage extends IWebViewMessageBase {
  type: IncomingWebViewMessageType.SET_IS_BIOMETRY_ENABLED;
  payload: WebViewBiometryMessagePayload["isBiometryEnabled"];
}

interface IWebViewIsReviewRequestAllowedMessage {
  type: IncomingWebViewMessageType.SET_IS_REVIEW_REQUEST_ALLOWED;
  payload: boolean;
}

type IWebViewMessage =
  | IWebViewBiometryMessage
  | IWebViewIsBiometryEnabledMessage
  | IWebViewIsReviewRequestAllowedMessage;

export const sendMessageToNativeApp = (message: OutcomingWebViewMessage) => {
  if (typeof window.ReactNativeWebView?.postMessage !== "function") return;
  const processedMessage = JSON.stringify(message);
  window.ReactNativeWebView.postMessage(processedMessage);
};

export const WebViewCtx = createContext<WebViewReviewCtx>({
  isBiometryEnabled: false,
  biometryLoginType: null,
  askForReview: () => {},
});

const WebViewInfo: FC<PropsWithChildren> = ({ children }): ReactElement => {
  const [isBiometryEnabled, setIsBiometryEnabled] = useState(false);
  const [biometryLoginType, setBiometryLoginType] =
    useState<Nullable<BiometryLoginType>>(null);
  const [isReviewRequestAllowed, setIsReviewRequestAllowed] = useState(true);

  const onMessageHandler = useCallback((e: Event) => {
    if (!(e instanceof MessageEvent)) return;
    const { type, payload } = e.data as IWebViewMessage;
    switch (type) {
      case IncomingWebViewMessageType.SET_BIOMETRY: {
        const {
          isBiometryEnabled: incomingIsBiometryEnabled,
          biometryLoginType: incomingBiometryLoginType,
        } = payload;

        if (incomingIsBiometryEnabled !== isBiometryEnabled) {
          setIsBiometryEnabled(incomingIsBiometryEnabled);
        }
        if (incomingBiometryLoginType !== biometryLoginType) {
          setBiometryLoginType(incomingBiometryLoginType);
        }
        break;
      }
      case IncomingWebViewMessageType.SET_IS_BIOMETRY_ENABLED: {
        if (payload !== isBiometryEnabled) {
          setIsBiometryEnabled(payload);
        }
        break;
      }
      case IncomingWebViewMessageType.SET_IS_REVIEW_REQUEST_ALLOWED: {
        setIsReviewRequestAllowed(payload);
        break;
      }
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (window.ReactNativeWebView)
      document.addEventListener("message", onMessageHandler);

    return () => document.removeEventListener("message", onMessageHandler);
  }, [onMessageHandler]);

  const onAskForReviewHandler = () => {
    if (!isReviewRequestAllowed) return;
    sendMessageToNativeApp({
      type: OutcomingWebViewMessageType.REQUEST_REVIEW,
    });
  };

  if (!window.ReactNativeWebView) return children as ReactElement;

  return (
    <WebViewCtx.Provider
      value={{
        askForReview: onAskForReviewHandler,
        isBiometryEnabled,
        biometryLoginType,
      }}
    >
      {children}
    </WebViewCtx.Provider>
  );
};

export default WebViewInfo;
