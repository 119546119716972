import { useFormikContext } from "formik";

import __ from "web/Layout/Translations";

import LetterCounter from "web/Components/Common/Form/LetterCounter";
import Field from "web/Components/Common/Form/NewField";

import useContactFormCategories, {
  IContactFormCategoriesValues,
} from "web/hooks/useContactFormCategories/useContactFormCategories";

import { useAppContext } from "web/context/app";

import {
  benefitTypeFieldShape,
  categoryFieldShape,
  emailFieldShape,
  productDescriptionFieldShape,
  productIdFieldShape,
  subjectFieldShape,
} from "../../constants";
import classes from "./fields.scss";

export const optionsFields = [
  productIdFieldShape,
  categoryFieldShape,
  benefitTypeFieldShape,
  subjectFieldShape,
  productDescriptionFieldShape,
  emailFieldShape,
];

const ProductInformationForm = () => {
  const { isMobile } = useAppContext();
  const { values, setFieldValue } = useFormikContext<
    IContactFormCategoriesValues & {
      [name: string]: string;
    }
  >();

  const { helpCategories, benefitTypes, subjects } = useContactFormCategories(
    values,
    setFieldValue as (...args: unknown[]) => void
  );

  return (
    <div className={classes.fields}>
      {optionsFields.map((field) => {
        const { hidden, options } = getOptions(field.name, {
          helpCategories,
          benefitTypes,
          subjects,
          chosenCategory: values.category as string,
        });
        return (
          <div key={field.id} className={getFieldStyle(field.name, isMobile)}>
            <Field
              type={hidden ? "hidden" : field.type}
              name={field.name}
              options={options}
              id={field.id}
              key={field.key}
              component={field.component}
              placeholder={__(field.placeholder as string)}
              label={__(field.label)}
              rules={field.rules}
              readonly={field.readonly}
            />
            {field.name === "description" && (
              <LetterCounter
                maxCount={3000}
                currentCount={values.description.length}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

const getFieldStyle = (name: string, isMobile: boolean) => {
  if (name === "description") return classes.descriptionField;

  return isMobile ? classes.fieldMobile : classes.field;
};

const getOptions = (
  name: string,
  options: {
    helpCategories: string[];
    benefitTypes: {
      value: string;
      label: string;
    }[];
    subjects: { value: string; label: string }[];
    chosenCategory: string;
  }
) => {
  switch (name) {
    case "category":
      return {
        options: options.helpCategories,
        hidden: false,
      };
    case "benefitType":
      return {
        options: options.benefitTypes,
        hidden: Boolean(!options.benefitTypes?.length),
      };
    case "subject":
      return {
        options: options.subjects,
        hidden:
          Boolean(!options.subjects?.length) ||
          Boolean(!options.benefitTypes?.length && !options.subjects?.length) ||
          options.chosenCategory === "lifestyle",
      };
    default:
      return {
        options: [],
        hidden: false,
      };
  }
};

export default ProductInformationForm;
