/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable consistent-return */
import { Form, Formik } from "formik";
import { isEmpty } from "lodash";
import { FC, useCallback, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import { ROOM_OFFERS_ID } from "web/Pages/Product/Shared/RoomOffers/Shared/WrapperWithTitle/Desktop/wrapperWithTitle";
import DateFields from "web/Pages/Tourism/DomesticTourismForm/DateFields";
import Localization from "web/Pages/Tourism/DomesticTourismForm/Localization";
import TourismObject from "web/Pages/Tourism/DomesticTourismForm/TourismObject";
import {
  IDomesticTourismFormField,
  LocationField,
  dateFields,
  locationField,
  names,
  roomField,
  tourismObjectField,
} from "web/Pages/Tourism/DomesticTourismForm/domesticTourismFormFields";

import { CampaignGraphicsContext } from "web/Layout/CampaignGraphics/campaignGraphics";
import __ from "web/Layout/Translations";

import Banner from "web/Components/Common/Banner";
import Button from "web/Components/Common/Button/button";

import jsonParse from "web/utils/data/parser/string/jsonParse";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import { validateFields } from "web/utils/system/formValidator/validation";
import getSearchParameter from "web/utils/system/url/getSearchParameter";

import { pageName, searchName } from "web/constants/toolbar";
import urls from "web/constants/urls";

import { ICampaignGraphics } from "web/types/CampaignGraphics";
import type { PropsWithClasses } from "web/types/Common";
import type { ILocationItem } from "web/types/Geolocation";

import classify from "web/classify";
import { useAppContext } from "web/context/app";
import { useGoogleContext } from "web/context/google.context";
import {
  IAnixeFiltersRoom,
  IAnixeFiltersSelectedDates,
  setSelectedDates,
  setSelectedLocation,
  setSelectedObject,
  setSelectedRooms,
} from "web/features/anixeFilters/anixeFiltersSlice";

import DomesticTourismFormClearButton from "./ClearButton/clearButton";
import Room from "./Room";
import defaultClasses from "./domesticTourismForm.scss";

const dateField = {
  name: "date",
  subfields: dateFields,
};

const createUrlFromValues = (
  values: Record<string, unknown>,
  path: string,
  searchParams: string,
  searchValue: string,
  shortName: string
) => {
  const keys = values && Object.keys(values);
  const params = new URLSearchParams(searchParams);

  // we start every new search from page 1
  params.delete(pageName);

  const paramsString = isArrayHasItems(keys)
    ? keys.reduce((result, key) => {
        const valueData = values[key];
        params.delete(key);
        switch (key) {
          case names.tourismObject: {
            if (valueData) {
              params.set(key, encodeURIComponent(valueData as string));
            }
            break;
          }
          case names.location: {
            if (valueData) {
              params.set(key, encodeURIComponent(JSON.stringify(valueData)));
            }
            break;
          }
          case names.rooms: {
            if (valueData && isArrayHasItems(valueData)) {
              params.set(key, encodeURIComponent(JSON.stringify(valueData)));
            }
            break;
          }
          default: {
            if (valueData) {
              params.set(key, encodeURIComponent(valueData as string));
            }
            break;
          }
        }

        if (!!searchValue && !!shortName) {
          params.set(searchName, shortName);
        }
        return params.toString();
      }, "")
    : "";

  return `${path}?${paramsString}`;
};

const getUrlWithParams = (
  pathname: string,
  search: string,
  {
    tourismObject,
    location,
    dates,
    rooms,
  }: {
    tourismObject: string;
    location: ILocationItem;
    dates: IAnixeFiltersSelectedDates;
    rooms: unknown;
  }
) => {
  const params = new URLSearchParams(search);

  const paramsMap = new Map([
    [[names.tourismObject], tourismObject],
    [[names.location], location],
    [[names.dateFrom], dates.dateFrom],
    [[names.dateTo], dates.dateTo],
    [[names.rooms], rooms],
  ]);

  paramsMap.forEach((value, key) => {
    if (!value || (typeof value === "object" && isEmpty(value))) return;
    params.set(
      // @ts-ignore
      key,
      encodeURIComponent(
        typeof value === "string" ? value : JSON.stringify(value)
      )
    );
  });
  return `${pathname}?${params.toString()}`;
};

const scrollToOffers = (elementId: string, search: string) => {
  const element = document.getElementById(elementId);
  const dateFrom = getSearchParameter({ name: names.dateFrom, search });
  const dateTo = getSearchParameter({ name: names.dateTo, search });
  if (element && dateFrom && dateTo) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

export enum DOMESTIC_TOURISM_FORM_VARIANTS {
  OFFER = "offer",
  LISTING = "listing",
  PRODUCT = "product",
}

interface IDomesticTourismFormProps {
  variant: "offer" | "listing" | "product";
  categoryUrl?: string;
}

const DomesticTourismForm: FC<PropsWithClasses<IDomesticTourismFormProps>> = ({
  variant,
  categoryUrl = "",
  classes = {
    root: "",
  },
}) => {
  const { search, pathname } = useLocation();
  const { push } = useHistory();
  const { geocoderService } = useGoogleContext();
  const { can_show_anixe_search: canShowAnixeSearch } = useSelector(
    (state) => state.app.storeConfig
  );
  const { isMobile } = useAppContext();

  const dispatch = useDispatch();
  const { selectedObject, selectedLocation, selectedDates, selectedRooms } =
    useSelector((state) => state.anixeFilters);

  const getTourismRWDBanner = () => {
    const { data } = useContext(CampaignGraphicsContext) as {
      data: { graphics: ICampaignGraphics[] };
    };
    const tourismOnlineRWDBanner = data?.graphics?.find((element) => {
      return element.position === "tourism_banner";
    });
    return tourismOnlineRWDBanner;
  };

  const initialValues = useMemo(() => {
    const locationParameter = getSearchParameter({
      name: names.location,
      search,
    });

    const tourismObjectParameter =
      getSearchParameter({
        name: names.tourismObject,
        search,
      }) ||
      getSearchParameter({
        name: "search",
        search,
      });

    const locationParameterParsed = locationParameter
      ? jsonParse(locationParameter)
      : null;
    const roomsParameter = getSearchParameter({ name: names.rooms, search });
    const roomsParameterParsed = roomsParameter
      ? jsonParse(roomsParameter)
      : null;
    const dateFrom = getSearchParameter({ name: names.dateFrom, search });
    const dateTo = getSearchParameter({ name: names.dateTo, search });

    return {
      [names.tourismObject]: tourismObjectParameter || "",
      [names.location]:
        // @ts-ignore
        locationParameterParsed && Object.keys(locationParameterParsed).length
          ? locationParameterParsed
          : null,
      [names.dateFrom]: parseInt(dateFrom, 10) || null,
      [names.dateTo]: parseInt(dateTo, 10) || null,
      [names.rooms]:
        // @ts-ignore
        roomsParameterParsed && Object.keys(roomsParameterParsed).length
          ? roomsParameterParsed
          : [{ adults: 2, children: [] }],
    };
  }, [search]);

  const onSubmit = useCallback(
    (values: { tourism_location: { placeId: string } }) => {
      const placeId = values?.tourism_location?.placeId;
      const params = new URLSearchParams(search);
      const searchValue = params.get("search");

      const getLoactionName = () => {
        return new Promise((resolve, reject) => {
          if (placeId) {
            geocoderService?.geocode(
              {
                placeId,
              },
              (result, status) => {
                if (
                  status === "OK" &&
                  isArrayHasItems(result) &&
                  result[0]?.address_components
                ) {
                  const shortName = result[0].address_components.find(
                    (item) => item.short_name
                  )?.short_name;

                  if (shortName) {
                    resolve({
                      shortName,
                    });
                  }
                } else {
                  return reject(new Error("Location not found"));
                }
              }
            );
          } else {
            resolve({
              shortName: "",
            });
          }
        });
      };
      getLoactionName().then((response) => {
        const pathnameUrl =
          variant === DOMESTIC_TOURISM_FORM_VARIANTS.OFFER
            ? categoryUrl
            : pathname;

        const { shortName } = response as { shortName: string };
        const src = createUrlFromValues(
          values,
          pathnameUrl!,
          search,
          searchValue!,
          shortName
        );

        if (pathname + search !== src) {
          push(src);
        } else {
          scrollToOffers(ROOM_OFFERS_ID, search);
        }
      });
    },
    [push, categoryUrl, pathname, search]
  );

  useEffect(() => {
    if (
      !canShowAnixeSearch ||
      variant !== DOMESTIC_TOURISM_FORM_VARIANTS.LISTING
    )
      return;

    const tourismObjectParameter = initialValues[names.tourismObject];
    const locationParameter = initialValues[names.location];
    const dateFrom = initialValues[names.dateFrom];
    const dateTo = initialValues[names.dateTo];
    const dates = dateFrom &&
      dateTo && {
        dateFrom,
        dateTo,
      };
    const rooms = initialValues[names.rooms];
    const dispatchMap = new Map<unknown, unknown>([
      [
        tourismObjectParameter,
        () => setSelectedObject(tourismObjectParameter as string),
      ],
      [
        locationParameter,
        () => setSelectedLocation(locationParameter as ILocationItem),
      ],
      [
        dates,
        () => setSelectedDates(dates as unknown as IAnixeFiltersSelectedDates),
      ],
      [rooms, () => setSelectedRooms(rooms as IAnixeFiltersRoom[])],
    ]);

    dispatchMap.forEach((action) => {
      // @ts-ignore
      dispatch(action());
    });

    const isDataFromParams =
      (locationParameter || tourismObjectParameter) &&
      dateFrom &&
      dateTo &&
      rooms;

    if (isDataFromParams) {
      return;
    }

    const isDataFromRedux =
      (selectedLocation?.placeId || selectedObject) &&
      selectedDates &&
      selectedRooms;
    const url = isDataFromRedux
      ? getUrlWithParams(pathname, search, {
          tourismObject: selectedObject,
          location: selectedLocation,
          dates: selectedDates,
          rooms,
        })
      : urls.tourism;

    const timeout = setTimeout(() => {
      push(url);
    }, 0);
    return () => clearTimeout(timeout);
  }, [initialValues]);

  useEffect(() => {
    if (variant !== DOMESTIC_TOURISM_FORM_VARIANTS.PRODUCT) return;

    const observer = new MutationObserver((mutationList, observerInstance) => {
      const roomOffers = document.getElementById(ROOM_OFFERS_ID);
      if (roomOffers) {
        scrollToOffers(ROOM_OFFERS_ID, search);
        observerInstance.disconnect();
      }
    });

    observer.observe(document, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const fields = [tourismObjectField, locationField, dateField, roomField];
  const fieldsToValidate: IDomesticTourismFormField[] = [
    ...dateFields,
    roomField,
  ];
  if (
    canShowAnixeSearch &&
    variant !== DOMESTIC_TOURISM_FORM_VARIANTS.PRODUCT
  ) {
    fieldsToValidate.push(...[tourismObjectField, locationField]);
  }
  const validate = validateFields(fieldsToValidate);

  const initialValuesWithRedux = {
    ...initialValues,
    [names.tourismObject]: initialValues[names.tourismObject] || selectedObject,
    [names.location]: initialValues[names.location] || selectedLocation,
    [names.rooms]: initialValues[names.rooms] || selectedRooms,
    [names.dateFrom]: initialValues[names.dateFrom] || selectedDates?.dateFrom,
    [names.dateTo]: initialValues[names.dateTo] || selectedDates?.dateTo,
  };

  const isConfirmationFixed =
    variant !== DOMESTIC_TOURISM_FORM_VARIANTS.OFFER ||
    pathname === urls.tourism;

  const isCachedData =
    selectedLocation?.placeId || selectedObject || selectedDates;

  return (
    <div className={classes.root}>
      <Formik
        // @ts-ignore
        initialValues={initialValuesWithRedux}
        // @ts-ignore
        validate={validate}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className={classes.wrapper}>
            {isMobile && getTourismRWDBanner() && (
              <div className={classes.bannerContainer}>
                <Banner banner={getTourismRWDBanner()!} />
              </div>
            )}
            {fields.map((field) => {
              switch (field.name) {
                case variant !== DOMESTIC_TOURISM_FORM_VARIANTS.PRODUCT &&
                  !!canShowAnixeSearch &&
                  names.tourismObject: {
                  return (
                    <div className={classes.field} key={field.name}>
                      <TourismObject
                        field={field as typeof tourismObjectField}
                      />
                    </div>
                  );
                }
                case variant !== DOMESTIC_TOURISM_FORM_VARIANTS.PRODUCT &&
                  !!canShowAnixeSearch &&
                  names.location: {
                  return (
                    <div className={classes.field} key={field.name}>
                      <Localization field={field as LocationField} />
                    </div>
                  );
                }
                case dateField.name: {
                  return (
                    <div className={classes.field} key={field.name}>
                      <DateFields
                        fields={dateField.subfields}
                        variant={variant}
                      />
                    </div>
                  );
                }
                case names.rooms: {
                  return (
                    <div className={classes.field} key={field.name}>
                      <Room />
                    </div>
                  );
                }
                default: {
                  return null;
                }
              }
            })}
            <div
              className={`${classes.confirmation} ${
                isConfirmationFixed ? classes.fixed : ""
              }`}
            >
              <DomesticTourismFormClearButton
                canShow={
                  !!(
                    variant === DOMESTIC_TOURISM_FORM_VARIANTS.OFFER &&
                    isCachedData
                  )
                }
                values={values}
                setFieldValue={setFieldValue}
                classes={classes}
              />
              {variant === DOMESTIC_TOURISM_FORM_VARIANTS.PRODUCT ? (
                <Button
                  type="submit"
                  variant="secondary"
                  classes={{ root_secondary: classes.confirmationButton }}
                >
                  {__("Zatwierdź filtry")}
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="tertiary"
                  classes={{ root_secondary: classes.confirmationButton }}
                  block
                >
                  {__("Wyszukaj")}
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default classify<PropsWithClasses<IDomesticTourismFormProps>>(
  defaultClasses
)(DomesticTourismForm);
