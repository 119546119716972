// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prepaidCardDescription-row-jzJ{margin:0 0 60px}`, "",{"version":3,"sources":["webpack://./web/Pages/Customer/Content/PrepaidCards/ProductPrepaidCard/Content/prepaidCardDescription/prepaidCardDescription.scss"],"names":[],"mappings":"AAIuB,gCACrB,eAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .row {\n  margin: 0 0 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `prepaidCardDescription-row-jzJ`
};
export default ___CSS_LOADER_EXPORT___;
