import { FC } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import __ from "web/Layout/Translations";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import { IHelpCenterCategoryChild } from "web/types/HelpCenter";

import Anchor from "./Anchor";
import classes from "./linksContainer.scss";

interface IHelpCenterDesktopLinksContainerProps {
  section: IHelpCenterCategoryChild;
}

const MAX_COUNT_TO_SHOW = 5;

const HelpCenterDesktopLinksContainer: FC<
  IHelpCenterDesktopLinksContainerProps
> = ({ section }) => {
  const { name, articles, articles_count: count, url_key: urlKey } = section;
  const { help_center_prefix: prefix } = useSelector(
    (state) => state.app.storeConfig
  );

  const { langLinkSuffix, isParamLangEn } = useLangFromParams();

  const link = `/${prefix}/${urlKey}.html${langLinkSuffix}`;
  const articlesProcessed =
    count > MAX_COUNT_TO_SHOW && articles.length !== MAX_COUNT_TO_SHOW
      ? articles.slice(0, MAX_COUNT_TO_SHOW)
      : articles;

  return (
    <div className={classes.root}>
      <Link className={classes.link} to={link}>
        <h2 className={classes.title}>{name}</h2>
      </Link>

      <ul className={classes.list}>
        {articlesProcessed?.map(({ id, title, url_key: articleUrlKey }) => {
          const articleLink = `/${prefix}/${articleUrlKey}.html${langLinkSuffix}`;

          return (
            <li key={id}>
              <Anchor title={title} url={articleLink} />
            </li>
          );
        })}
      </ul>
      {count > MAX_COUNT_TO_SHOW ? (
        <Link className={classes.linkUnderline} to={link}>
          {isParamLangEn ? "See all articles" : __("Zobacz wszystkie artykuły")}
        </Link>
      ) : null}
    </div>
  );
};

export default HelpCenterDesktopLinksContainer;
