// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.helpCenter-container-Wim{margin-top:40px}.helpCenter-header-yue{font-size:44px;margin-bottom:30px}.helpCenter-title-bYx{margin-bottom:32px;font-size:44px}`, "",{"version":3,"sources":["webpack://./web/Pages/HelpCenter/Desktop/helpCenter.scss"],"names":[],"mappings":"AAIuB,0BACrB,eAAA,CAGF,uBACE,cAAA,CACA,kBAAA,CAGF,sBACE,kBAAA,CACA,cAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .container {\n  margin-top: 40px;\n}\n\n.header {\n  font-size: 44px;\n  margin-bottom: 30px;\n}\n\n.title {\n  margin-bottom: 32px;\n  font-size: 44px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `helpCenter-container-Wim`,
	"header": `helpCenter-header-yue`,
	"title": `helpCenter-title-bYx`
};
export default ___CSS_LOADER_EXPORT___;
