import { FC } from "react";

import Title from "web/Components/Common/Title";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import { IHelpCenterCategoryChild } from "web/types/HelpCenter";

import CategoryTile from "../CategoryTile/categoryTile";
import classes from "./category.scss";
import titleClasses from "./titleClasses.scss";

interface IHelpCenterMobileCategory {
  label: string;
  prefix: string;
  topics: IHelpCenterCategoryChild[];
}

const HelpCenterMobileCategory: FC<IHelpCenterMobileCategory> = ({
  label,
  topics,
  prefix,
}) => {
  const { langLinkSuffix } = useLangFromParams();
  return (
    <li className={classes.root}>
      <Title classes={titleClasses} name={label} />
      <ul className={classes.subcategoriesList}>
        {topics.map(({ id, name, url_key }) => {
          const link = `/${prefix}/${url_key}.html${langLinkSuffix}`;

          return (
            <CategoryTile key={id} link={link}>
              {name}
            </CategoryTile>
          );
        })}
      </ul>
    </li>
  );
};

export default HelpCenterMobileCategory;
