import { useQuery } from "@apollo/client";
import { FC } from "react";
import { useSelector } from "react-redux";

import { ErrorComponentWithContainer } from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";
import { getLanguageCode } from "web/Layout/Translations/LanguageWrapper";
import WorkInProgress from "web/Layout/WorkInProgress";

import helpCenterAllCategories from "web/queries/default/helpCenterAllCategories.graphql";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import { IHelpCenterCategory } from "web/types/HelpCenter";

import { useAppContext } from "web/context/app";

import Desktop from "./Desktop";
import Mobile from "./Mobile";

interface IHelpCenterPageProps {
  categories: IHelpCenterCategory[];
}

const HelpCenterPage: FC<IHelpCenterPageProps> = ({ categories }) => {
  const { isMobile } = useAppContext();
  const { display_help_center_plug } = useSelector(
    (state) => state.app.storeConfig
  );

  if (+display_help_center_plug) return <WorkInProgress />;

  const Comp = isMobile ? Mobile : Desktop;

  return <Comp categories={categories} />;
};

const HelpCenter: FC = () => {
  const { languageFromParam } = useLangFromParams();
  const { error, loading, data } = useQuery(helpCenterAllCategories, {
    variables: {
      lang: languageFromParam || getLanguageCode(),
    },
  });

  switch (true) {
    case !!loading && (!data || !Object.keys(data).length): {
      return <Loading />;
    }
    case !!error: {
      newRelicErrorReport(
        error,
        "web-app/web/Pages/HelpCenter/container.js - 51"
      );
      console.error(error);
      return <ErrorComponentWithContainer />;
    }
    default: {
      return (
        data?.helpCenterCategories?.categories && (
          <HelpCenterPage categories={data.helpCenterCategories.categories} />
        )
      );
    }
  }
};

export default HelpCenter;
