import { number, string } from "prop-types";

import __ from "web/Layout/Translations";

import PrepaidCardAside from "./PrepaidCardAside";
import PrepaidCardType from "./PrepaidCardType";
import Title from "./Title";
import classes from "./aside.scss";

const Aside = ({
  bankTotalPoints,
  type,
  id,
  className,
  issuanceFee,
  issuanceFeePayer,
  rechargeCommission,
  rechargeCommissionPayer,
  chargeDeadlineDate,
  cardNumber,
}) => {
  const title =
    type === "CREATE"
      ? `${__("Nowa karta przedpłacona")} + ${__("zasilenie")}`
      : __("Zasilenie karty przedpłaconej");
  return (
    <aside className={className}>
      <div className={classes.asideContainer}>
        <PrepaidCardType label="Karta przedpłacona" />
        <Title title={title} />
        <PrepaidCardAside
          bankTotalPoints={bankTotalPoints}
          id={id}
          type={type}
          issuanceFee={issuanceFee}
          issuanceFeePayer={issuanceFeePayer}
          rechargeCommission={rechargeCommission}
          rechargeCommissionPayer={rechargeCommissionPayer}
          chargeDeadlineDate={chargeDeadlineDate}
          cardNumber={cardNumber}
        />
      </div>
    </aside>
  );
};

Aside.propTypes = {
  bankTotalPoints: number.isRequired,
  id: number.isRequired,
  className: string,
  type: string,
  issuanceFeePayer: string,
  issuanceFee: number,
  rechargeCommission: number,
  rechargeCommissionPayer: string,
  chargeDeadlineDate: string,
  cardNumber: string,
};

Aside.defaultProps = {
  issuanceFee: 0,
  issuanceFeePayer: "",
  type: "",
  className: "",
};

export default Aside;
