/* eslint-disable consistent-return */
import { number, object } from "prop-types";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";

import ErrorBoundary from "web/Layout/ErrorBoundary/errorBoundary";
import __ from "web/Layout/Translations";

import BreadcrumbsCommon from "web/Components/Common/Breadcrumbs";

import urls from "web/constants/urls";

import Aside from "./Aside";
import Content from "./Content";
import Gallery from "./Gallery";
import Legals from "./Legals";
import ShortReviews from "./ShortReviews";
import classes from "./productPrepaidCard.scss";

const ProductPrepaidCard = ({ prepaidCard, bankTotalPoints }) => {
  const breadcrumbsList = [
    {
      label: __("Strona główna"),
      url: urls.home,
      dataT1: "breadcrumbs_main_page",
    },
    {
      label: __("Karta przedpłacona"),
      url: "/karta-przedplacona",
      dataT1: "breadcrumbs_prepaid_card",
    },
  ];

  useEffect(() => {
    if (prepaidCard && !prepaidCard.available) {
      return <Redirect to={urls.customerPrepaidCards} />;
    }
  }, [prepaidCard]);

  return (
    <div className={`container ${classes.root}`}>
      <BreadcrumbsCommon breadcrumbs={breadcrumbsList} />
      <div className={classes.row}>
        <div className={classes.main}>
          <ErrorBoundary>
            <Gallery image={prepaidCard.imageUrl || ""} />
          </ErrorBoundary>
          <ErrorBoundary>
            <ShortReviews />
            <Content product={prepaidCard} />
            <Legals product={prepaidCard} />
          </ErrorBoundary>
        </div>
        <ErrorBoundary>
          <Aside
            bankTotalPoints={bankTotalPoints}
            type={prepaidCard.type}
            className={classes.aside}
            id={prepaidCard.id}
            issuanceFee={prepaidCard.issuanceFee}
            issuanceFeePayer={prepaidCard.issuanceFeePayer}
            rechargeCommission={prepaidCard.rechargeCommission}
            rechargeCommissionPayer={prepaidCard.rechargeCommissionPayer}
            cardNumber={prepaidCard.cardNumber}
            chargeDeadlineDate={prepaidCard.chargeDeadlineDate}
          />
        </ErrorBoundary>
      </div>
      <ErrorBoundary>
        {/* <AdditionalHtmlContent /> */}
        {/* <ProductUpSell /> */}
      </ErrorBoundary>
    </div>
  );
};

ProductPrepaidCard.propTypes = {
  prepaidCard: object.isRequired,
  bankTotalPoints: number.isRequired,
};

export default ProductPrepaidCard;
