import { string } from "prop-types";

import __ from "web/Layout/Translations";

import classes from "./prepaidCardType.scss";

const PrepaidCardType = ({ label }) => {
  return (
    <aside className={classes.root}>
      {__("Typ produktu")}: <span>{__(label)}</span>
    </aside>
  );
};

PrepaidCardType.propTypes = {
  label: string.isRequired,
};

export default PrepaidCardType;
