/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { BaseQueryFn, FetchArgs } from "@reduxjs/toolkit/query";
import { createApi } from "@reduxjs/toolkit/query/react";

import renewAfterGetToken from "web/api/renewAfterGetToken";

import isValidToken from "web/utils/system/essentials/isValidToken";
import isValidTokenAccess from "web/utils/system/essentials/isValidTokenAccess";

import baseQueryMSI from "./baseQueryMSI";
import baseQueryMagento from "./baseQueryMagento";
import isMSIQuery from "./utils/isMSIQuery";

const baseQuery: BaseQueryFn<string | FetchArgs> = async (
  args,
  api,
  extraOptions,
  token?: string
) => {
  let result;
  if (isMSIQuery(args)) {
    if (!isValidTokenAccess() && !token) {
      return renewAfterGetToken(
        JSON.stringify(args),
        (tokenFromRenewal: string) =>
          // @ts-ignore
          baseQuery(args, api, extraOptions, tokenFromRenewal),
        true
      );
    }
    result = await baseQueryMSI(
      args,
      { ...api, extra: { token } },
      extraOptions
    );
  } else {
    if (!isValidToken() && !token) {
      return renewAfterGetToken(
        JSON.stringify(args),
        (tokenFromRenewal: string) =>
          // @ts-ignore
          baseQuery(args, api, extraOptions, tokenFromRenewal),
        false
      );
    }
    result = await baseQueryMagento(
      args,
      { ...api, extra: { token } },
      extraOptions
    );
  }

  if (result?.error && result.error.status === 401) {
    return renewAfterGetToken(
      JSON.stringify(args),
      baseQuery.bind(this, args, api, extraOptions),
      isMSIQuery(args)
    );
  }

  return result;
};

const apiSlice = createApi({
  reducerPath: "api",
  baseQuery,
  tagTypes: [
    "Wishlist",
    "Reminders",
    "Company",
    "Messages",
    "Messages_unread",
    "Popups",
    "BuyNow",
    "Customer",
    "Employee",
    "Employee_agreements",
    "Employee_configuration",
    "Employee_pointsHistory",
    "PaymentsMethods",
    "PrepaidCards",
    "PrepaidCards_details",
    "PrepaidCardsOrders",
    "PrepaidCardsOrders_details",
    "PointsBank",
    "CurrentUserSubscriptions",
    "AvailableUserBenefits",
    "EmployeeSubscriptionDraft",
    "EmployeeSubscriptionDraftForms",
    "EmployeeSubscriptionDraftAgreements",
    "FilterBenefitGroups",
    "DuplicateCardConfiguration",
    "Employee_RG_Additional_Data",
    "DuplicateCardConfiguration",
    "CodeSettings",
    "ProductCode",
    "BarCode",
    "MFAConfiguration",
  ],
  endpoints: () => ({}),
});

export default apiSlice;
