/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Formik } from "formik";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import __ from "web/Layout/Translations";

import { IApiRequestMethods } from "web/api/apiRequestTypes";

import sanitizeObject from "web/utils/data/parser/object/sanitizeObject";
import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";
import {
  getInitialValues,
  validateFields,
} from "web/utils/system/formValidator/validation";

import restUrls from "web/constants/restUrls";
import urls from "web/constants/urls";

import { request } from "web/api";
import { useAppContext } from "web/context/app";
import {
  setNotificationError,
  setNotificationSuccess,
} from "web/features/app/appSlice";
import { useGetCustomerDetailsQuery } from "web/features/customer/customerApiSlice";

import Breadcrumbs from "../HelpCenter/Shared/Breadcrumbs";
import ContactForm from "./Desktop/contactForm";
import ContactFormMobile from "./Mobile/contactFormMobile";
import {
  newBenefitFieldsMap, // param,
  productInformationFieldsAllMap,
  radioValues,
} from "./constants";
import classes from "./desktop.scss";
import getContactFormSource from "./utils/getContactFormSource";

const ContactFormContainer = () => {
  const dispatch = useDispatch();
  const { data: customer } = useGetCustomerDetailsQuery();
  const { email } = customer || {};
  const newBenefitItem = newBenefitFieldsMap.find(
    (item) => item.type === "email"
  );
  const productInformationField = productInformationFieldsAllMap.find(
    (item) => item.type === "email"
  );

  if (newBenefitItem) {
    // @ts-ignore
    newBenefitItem.initialValue = email;
  }
  if (productInformationField) {
    // @ts-ignore
    productInformationField.initialValue = email;
  }

  const { isMobile, appMode } = useAppContext();
  const history = useHistory();
  const isNewBenefit = false;

  const validate = isNewBenefit
    ? validateFields(newBenefitFieldsMap)
    : validateFields(productInformationFieldsAllMap);
  const initialValues = isNewBenefit
    ? getInitialValues(newBenefitFieldsMap)
    : getInitialValues(productInformationFieldsAllMap);

  const submit = useCallback(
    async (
      values: Record<string, string>,
      actions: { resetForm: () => void }
    ) => {
      const endpoint = isNewBenefit
        ? restUrls.helpCenterAddService
        : restUrls.helpCenterIndividual;
      const body = {
        form: isNewBenefit
          ? {
              category: values.category,
              types_of_benefits: "default",
              scope_of_assistance: "default",
              object_name: values.placeName,
              province: values.province || "none",
              city: values.city,
              web_page: values.webSite,
              phone_number: values.phoneNumber,
              description_of_benefit: values.description,
              email: values.email,
            }
          : {
              product_id: values.productId || "none",
              description_of_notification: values.description,
              email: values.email,
              category: values.category,
              types_of_benefits: values.benefitType,
              scope_of_assistance: values.subject,
            },
        source: getContactFormSource(isMobile, appMode),
      };
      try {
        await request(endpoint, {
          method: IApiRequestMethods.POST,
          body: JSON.stringify(sanitizeObject(body)),
        });
        dispatch(
          setNotificationSuccess({
            message: __("Formularz został pomyślnie wysłany."),
          })
        );
        actions.resetForm();
        history.push(urls.helpCenter);
      } catch (error) {
        newRelicErrorReport(
          error,
          "web-app/web/Pages/ContactForm/container.js - 93"
        );
        dispatch(
          setNotificationError({
            message:
              (error as { message: string })?.message ||
              __("Nie udało się wysłać formularza."),
          })
        );
        console.error(error);
      }
    },
    [isNewBenefit, setNotificationSuccess, setNotificationError]
  );

  if (isMobile) {
    return (
      <>
        <Formik
          onSubmit={submit}
          initialValues={{
            statement: isNewBenefit
              ? radioValues.newBenefit
              : radioValues.productInformation,
            ...initialValues,
          }}
          // @ts-ignore
          validate={validate}
          enableReinitialize
        >
          <ContactFormMobile />
        </Formik>
      </>
    );
  }
  return (
    <div className={classes.root}>
      <div
        className={`container ${classes.body}`}
        data-t1="category_desktop_container"
      >
        <Breadcrumbs breadcrumbs={[]} />
        <Formik
          onSubmit={submit}
          initialValues={{
            statement: isNewBenefit
              ? radioValues.newBenefit
              : radioValues.productInformation,
            ...initialValues,
          }}
          // @ts-ignore
          validate={validate}
          enableReinitialize
        >
          <ContactForm />
        </Formik>
      </div>
    </div>
  );
};

export default ContactFormContainer;
