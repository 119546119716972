import { node, string } from "prop-types";

import classes from "./fieldContainer.scss";

const FieldContainer = ({ label, currency, children }) => {
  return (
    <div className={classes.fieldContainer}>
      <p className={classes.label}>{label}</p>
      <div className={classes.fieldInnerContainer}>
        {children}
        <p className={classes.currency}>{currency}</p>
      </div>
    </div>
  );
};

FieldContainer.propTypes = {
  label: string,
  currency: string,
  children: node.isRequired,
};

FieldContainer.defaultProps = {
  label: "",
  currency: "zł",
};

export default FieldContainer;
