import classes from "./shortReviews.scss";

const ShortReviews = () => {
  return (
    <div className={classes.root}>
      {/* @TODO To be added after MVP */}
      {/* <Bestseller label="bestseller" /> */}
    </div>
  );
};

export default ShortReviews;
