import { object } from "prop-types";

import PrepaidCardDescription from "./prepaidCardDescription";

const Content = ({ product }) => {
  return <PrepaidCardDescription product={product} />;
};

Content.propTypes = {
  product: object.isRequired,
};

export default Content;
