/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Form, Formik } from "formik";
import { FC, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import { DOMESTIC_TOURISM_FORM_VARIANTS } from "web/Pages/Tourism/DomesticTourismForm";
import DomesticTourismFormClearButton from "web/Pages/Tourism/DomesticTourismForm/ClearButton/clearButton";
import TourismObject from "web/Pages/Tourism/DomesticTourismForm/TourismObject";
import {
  IDomesticTourismFormField,
  LocationField,
  dateFields,
  locationField,
  names,
  roomField,
  tourismObjectField,
} from "web/Pages/Tourism/DomesticTourismForm/domesticTourismFormFields";

import __ from "web/Layout/Translations";

import Button from "web/Components/Common/Button/button";

import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";

import jsonParse from "web/utils/data/parser/string/jsonParse";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import { validateFields } from "web/utils/system/formValidator/validation";
import getSearchParameter from "web/utils/system/url/getSearchParameter";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";
import { useAppContext } from "web/context/app";

import DateFields from "./DateFields";
import Localization from "./Localization";
import Room from "./Room";
import defaultClasses from "./domesticTourismForm.scss";

const dateField = {
  name: "date",
  subfields: dateFields,
};
const fields = [tourismObjectField, locationField, dateField, roomField];

export const createUrlFromValues = (
  values: Record<string, string>,
  path: string,
  searchParams: string
) => {
  const keys = values && Object.keys(values);
  const params = new URLSearchParams(searchParams);
  const paramsString = isArrayHasItems(keys)
    ? keys.reduce((result, key) => {
        const valueData = values[key];
        params.delete(key);
        switch (key) {
          case names.tourismObject: {
            if (valueData) {
              params.set(key, encodeURIComponent(valueData));
            }
            break;
          }
          case names.location: {
            if (valueData) {
              params.set(key, encodeURIComponent(JSON.stringify(valueData)));
            }
            break;
          }
          case names.rooms: {
            if (valueData && isArrayHasItems(valueData)) {
              params.set(key, encodeURIComponent(JSON.stringify(valueData)));
            }
            break;
          }
          default: {
            if (valueData) {
              params.set(key, encodeURIComponent(valueData));
            }
            break;
          }
        }
        return params.toString();
      }, "")
    : "";

  return `${path}?${paramsString}`;
};

interface IProductDomesticTourismFormMobileProps {
  variant: "offer" | "listing" | "product";
  categoryUrl?: string;
}

const ProductDomesticTourismFormMobile: FC<
  PropsWithClasses<IProductDomesticTourismFormMobileProps>
> = ({ variant, categoryUrl = "", classes = { root: "" } }) => {
  const { search, pathname } = useLocation();
  const { push } = useHistory();
  const { modal } = useAppContext();
  const { dispatch } = modal;
  const { can_show_anixe_search: canShowAnixeSearch } = useSelector(
    (state) => state.app.storeConfig
  );

  const fieldsToValidate: IDomesticTourismFormField[] = [
    ...dateFields,
    roomField,
  ];
  if (
    variant !== DOMESTIC_TOURISM_FORM_VARIANTS.PRODUCT &&
    canShowAnixeSearch
  ) {
    fieldsToValidate.push(locationField, tourismObjectField);
  }
  const validate = validateFields(fieldsToValidate);

  const closeModal = useCallback(() => {
    dispatch({ type: ModalActionTypes.RESET });
  }, [dispatch]);

  const initialValues = useMemo(() => {
    const locationParameter = getSearchParameter({
      name: names.location,
      search,
    });
    const locationParameterParsed = locationParameter
      ? jsonParse(locationParameter)
      : null;
    const roomsParameter = getSearchParameter({ name: names.rooms, search });
    const roomsParameterParsed = roomsParameter
      ? jsonParse(roomsParameter)
      : null;
    const dateFrom = getSearchParameter({ name: names.dateFrom, search });
    const dateTo = getSearchParameter({ name: names.dateTo, search });

    const tourismObjectParameter =
      getSearchParameter({
        name: names.tourismObject,
        search,
      }) ||
      getSearchParameter({
        name: "search",
        search,
      });

    return {
      [names.tourismObject]: tourismObjectParameter || null,
      [names.location]:
        // @ts-ignore
        locationParameterParsed && Object.keys(locationParameterParsed).length
          ? locationParameterParsed
          : null,
      [names.dateFrom]: dateFrom || null,
      [names.dateTo]: dateTo || null,
      [names.rooms]:
        // @ts-ignore
        roomsParameterParsed && Object.keys(roomsParameterParsed).length
          ? roomsParameterParsed
          : [{ adults: 2, children: [] }],
    };
  }, [search]);

  const onSubmit = useCallback(
    (values: Record<string, string>) => {
      const pathnameUrl =
        variant === DOMESTIC_TOURISM_FORM_VARIANTS.OFFER
          ? categoryUrl
          : pathname;
      const src = createUrlFromValues(values, pathnameUrl, search);
      if (src) {
        push(src);
        closeModal();
      }
    },
    [push, categoryUrl, pathname, search]
  );

  return (
    <div className={classes.root}>
      <Formik
        // @ts-ignore
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className={classes.wrapper}>
            {variant !== DOMESTIC_TOURISM_FORM_VARIANTS.PRODUCT &&
            variant !== DOMESTIC_TOURISM_FORM_VARIANTS.LISTING ? (
              <strong className={classes.header}>
                {__("Dokąd chcesz pojechać?")}
              </strong>
            ) : null}
            {fields.map((field) => {
              switch (field.name) {
                case variant !== DOMESTIC_TOURISM_FORM_VARIANTS.PRODUCT &&
                  canShowAnixeSearch &&
                  names.tourismObject: {
                  return (
                    <div className={classes.field} key={field.name}>
                      <TourismObject
                        field={field as typeof tourismObjectField}
                      />
                    </div>
                  );
                }
                case variant !== DOMESTIC_TOURISM_FORM_VARIANTS.PRODUCT &&
                  canShowAnixeSearch &&
                  names.location: {
                  return (
                    <div className={classes.field} key={field.name}>
                      <Localization field={field as LocationField} />
                    </div>
                  );
                }
                case dateField.name: {
                  return (
                    <div className={classes.field} key={field.name}>
                      <DateFields
                        fields={dateField.subfields}
                        variant={variant}
                        showErrorContainers={false}
                      />
                    </div>
                  );
                }
                case names.rooms: {
                  return (
                    <div className={classes.field} key={field.name}>
                      <Room />
                    </div>
                  );
                }
                default: {
                  return null;
                }
              }
            })}
            <div className={classes.confirmation}>
              <DomesticTourismFormClearButton
                canShow={variant === DOMESTIC_TOURISM_FORM_VARIANTS.LISTING}
                values={values}
                setFieldValue={setFieldValue}
                classes={classes}
              />
              {variant === DOMESTIC_TOURISM_FORM_VARIANTS.PRODUCT ? (
                <>
                  <Button
                    type="reset"
                    variant="secondary"
                    classes={{ root_secondary: classes.confirmationButton }}
                    onClick={closeModal}
                  >
                    {__("Anuluj")}
                  </Button>
                  <Button
                    type="submit"
                    classes={{ root_secondary: classes.confirmationButton }}
                  >
                    {__("Zatwierdź")}
                  </Button>
                </>
              ) : (
                <Button
                  type="submit"
                  variant="tertiary"
                  classes={{ root_secondary: classes.confirmationButton }}
                  block
                >
                  {__("Wyszukaj")}
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default classify<
  PropsWithClasses<IProductDomesticTourismFormMobileProps>
>(defaultClasses)(ProductDomesticTourismFormMobile);
