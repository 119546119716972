/* eslint-disable consistent-return */
import {
  ActionCreatorWithPayload,
  Dictionary,
  EntityId,
} from "@reduxjs/toolkit";
import { DocumentNode } from "graphql";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import MapWrapper from "web/utils/system/storage/storagesAsync/mapWrapper";
import { getFlattedData } from "web/utils/system/storage/storagesAsync/utils";

import { magentoUrlKey, msURlKey } from "web/constants/graphqlUrls";

import { FacetsStats } from "web/types/Product";

import { RootState } from "web/store";

import addTimeExpiration, { ICachedItem } from "./utils/addTimeExpiration";
import fetchLackIds from "./utils/fetchLackIds";
import getCachedDataFromRedux from "./utils/getCachedDataFromRedux";
import getDataInOrder from "./utils/getDataInOrder";
import useDataCachedReducer from "./utils/useDataCachedReducer";

export type Identifiers =
  | { id: string | number }
  | { graphic_id: number }
  | { attribute_code: string };

interface IUseDataCachedArgs<T> {
  endpoint?: typeof magentoUrlKey | typeof msURlKey;
  query: DocumentNode;
  ids: (number | string)[];
  skip?: boolean;
  addToCacheAction: ActionCreatorWithPayload<
    readonly ICachedItem<T>[] | Record<EntityId, ICachedItem<T>>
  >;
  removeFromCacheAction: ActionCreatorWithPayload<readonly EntityId[], string>;
  selectEntities: (state: RootState) => Dictionary<ICachedItem<T>>;
  mapData: MapWrapper;
  isCategoryCounts?: boolean;
  isIdsFacetsStatsData?: boolean;
  identifier?: keyof Identifiers | string;
  additionalParameters?: string;
  additionalOptions?: Record<string, unknown>;
  isFullProduct?: boolean;
  isShortProduct?: boolean;
  isList?: boolean;
  TTL?: number;
  variables?: Record<string, unknown>;
}

interface IGraphQlData<T> {
  data: Record<string, { edges: { node: T }[] }>;
}

/**
 * this is for managing pending ids
 */
const useDataCached = <T extends Identifiers | FacetsStats>({
  ids,
  endpoint = msURlKey,
  query,
  skip = false,
  addToCacheAction,
  removeFromCacheAction,
  selectEntities,
  mapData,
  isCategoryCounts,
  isIdsFacetsStatsData,
  identifier = "id",
  additionalParameters,
  additionalOptions,
  isFullProduct,
  isShortProduct,
  isList,
  TTL,
  variables,
}: IUseDataCachedArgs<T>) => {
  const dataFromStore = useSelector(selectEntities) as Record<
    string,
    ICachedItem<T>
  >;
  const { id: storeId, token } = useSelector((state) => state.app.storeConfig);
  const { loading, error, data, setLoading, setData, setError } =
    useDataCachedReducer<T>();

  const dispatch = useDispatch();

  useEffect(() => {
    if (skip || !isArrayHasItems(ids)) {
      setData(data || []);
      return;
    }

    let isMounted = true;

    (async () => {
      setLoading();
      try {
        /**
         * get cached data and list of lacking, waiting and outdated ids
         */
        const { dataFromRedux, lackIds, idsWaiting, idsToRemoveFromCache } =
          getCachedDataFromRedux<T>({
            ids,
            dataFromStore,
            mapData,
            isFullProduct,
            hasAdditionalParameters: !!additionalParameters,
            isIdsFacetsStatsData,
          });

        if (idsToRemoveFromCache.size) {
          dispatch(removeFromCacheAction([...idsToRemoveFromCache]));
        }

        /**
         * fetch lack ids and get data from pending ids requests
         */
        mapData.set(
          lackIds,
          fetchLackIds({
            ids: lackIds,
            query,
            storeId,
            token,
            endpoint,
            additionalParameters,
            additionalOptions,
            isIdsFacetsStatsData,
            variables,
          })
        );

        const idsWaitingArray = [...idsWaiting];

        const [dataLackPages, idsWaitingData] = await Promise.all([
          mapData.get(lackIds),
          ...(isArrayHasItems(idsWaitingArray)
            ? [mapData.getPromiseByKey(idsWaitingArray[0])]
            : []),
        ]);

        mapData.delete(lackIds);

        /**
         * transform data, add to cache and set state
         */

        const dataLackFlatted = (dataLackPages
          .map((dataLack: IGraphQlData<T>) => {
            return getFlattedData<T>(dataLack, query);
          })
          .flat() || []) as T[];

        const idsWatingFlatted = (
          isArrayHasItems(idsWaitingData)
            ? getFlattedData<T>(idsWaitingData[0] as IGraphQlData<T>, query)
            : []
        ) as T[];

        const idsWaitingFiltered = idsWatingFlatted.filter((item) =>
          ids.some(
            (id) => `${id}` === `${item[identifier as keyof typeof item]}`
          )
        );

        const itemsFlatted = [...dataLackFlatted, ...idsWaitingFiltered];

        let itemsToSet = itemsFlatted as T[];
        if (itemsToSet.length - 1 < lackIds.length && isCategoryCounts) {
          itemsToSet = lackIds.map(
            (id) =>
              itemsToSet.find(
                (item) => +item[identifier as keyof Identifiers] === +id
              ) || { id: `${id}` }
          ) as T[];
        }
        const itemIdentifier = isIdsFacetsStatsData ? ids[0] : identifier;

        const dataWithExpiration = addTimeExpiration<T>(
          itemsToSet,
          itemIdentifier as keyof Identifiers,
          isShortProduct,
          TTL
        );

        dispatch(addToCacheAction(dataWithExpiration));
        if (isMounted) {
          const dataMerged = [...itemsFlatted, ...dataFromRedux];

          setData(isList ? getDataInOrder<T>(dataMerged, ids) : dataMerged);
        }
      } catch (error) {
        if (isMounted) {
          setError(error);
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [JSON.stringify(ids), skip]);

  return { loading, error, data };
};

export default useDataCached;
